export default class CountdownTimer {

  constructor() {
   }

  onTimesUp() {
     clearInterval(timerInterval);
     timerInterval = null;
     $("#recording_countdown").hide()
     Room.start_recording_timer()
   }

  start_countdown_timer() {
     var that = this;
     timeLeft = TIME_LIMIT;
     document.getElementById("base-timer-label").innerHTML = this.formatTime(timeLeft);
     $("#recording_countdown").show()
     timePassed = 0;
     timerInterval = setInterval(() => {
       timePassed = timePassed += 1;
       timeLeft = TIME_LIMIT - timePassed;
       document.getElementById("base-timer-label").innerHTML = that.formatTime(timeLeft);
       that.setCircleDasharray();
       if (timeLeft === 0) { that.onTimesUp() }
     }, 1000);
   }

  formatTime(time) {
     const minutes = Math.floor(time / 60);
     let seconds = time % 60;
     return `${seconds}`;
   }

  calculateTimeFraction() {
     const rawTimeFraction = timeLeft / TIME_LIMIT;
     return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
   }

  setCircleDasharray() {
     const circleDasharray = `${( this.calculateTimeFraction() * FULL_DASH_ARRAY ).toFixed(0)} 283`;
     document.getElementById("base-timer-path-remaining").setAttribute("stroke-dasharray", circleDasharray);
   }

}
