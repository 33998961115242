export default class Messages {

  constructor() {
   }

  update_unseen_chat_counter(event){
    if ($("#chat_dropdown:visible").length == 1) {
        $("#unseen_message_count").text("")
    } else {
      if ($("#unseen_message_count").text() == ""){
        $("#unseen_message_count").text("1")
      } else {
        var current_count = parseInt($("#unseen_message_count").text())
        $("#unseen_message_count").text(current_count + 1)
      }
    }
  }

  create(attendee_name, attendee_id, message){
      var current_time = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      var message_div = '';
      if ($("#attendee_id").val() != attendee_id){
        message_div = `<div class="flow-root">
          <div class="dark:text-gray-600 text-xs ml-5 -mb-1 text-gray-500">${attendee_name} at ${current_time}</div>
          <div class="dark:text-gray-700 text-sm dont-break-out bg-gray-300 w-3/4 mx-3 my-2 p-2 rounded-lg shadow">
            ${message}
          </div>
        </div>`
        message_div = message_div.replace(/((http:|https:)[^\s]+[\w])/g, '<a class="underline text-blue-500" href="$1" target="_blank">$1</a>')
      }else {
        message_div = `<div class="flow-root">
        <div class="dark:text-gray-600 text-xs mr-5 -mb-2 float-right text-gray-500">${attendee_name} at ${current_time}</div>
          <div class="text-sm dont-break-out bg-indigo-500 text-white float-right w-3/4 mx-3 my-2 p-2 rounded-lg flow-root shadow">
            ${message}
          </div>
        </div>`
        message_div = message_div.replace(/((http:|https:)[^\s]+[\w])/g, '<a class="underline text-blue-200" href="$1" target="_blank">$1</a>')
      }
      $("#chat_container").append(message_div)
      var d = $('#chat_container').parent();
      d.scrollTop(d.prop("scrollHeight"));
      this.update_unseen_chat_counter()
  }


}
