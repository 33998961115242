import {  Controller } from "stimulus"

export default class extends Controller {

  connect(){
    this.roomController = this.application.getControllerForElementAndIdentifier(this.element,"room");
    console.log("BINDING")
    if (typeof AgoraRTC !== 'undefined'){
      this.bindOnPlaybackChange()
      this.bindOnMicrophoneChange()
      this.bindOnCameraChange()
    } else {
      that = this;
      setTimeout(() => {
        if (typeof AgoraRTC !== 'undefined'){
          that.bindOnPlaybackChange()
          that.bindOnMicrophoneChange()
          that.bindOnCameraChange()
        } else{
          console.error("window.AgoraRTC2 not defined")
          Rollbar.error("window.AgoraRTC2_notdefined")
        }
      }, "1000")
    }
  }

  bindOnPlaybackChange(){
    AgoraRTC.onPlaybackDeviceChanged = async (info) => {
      var not_connected = $("#join_room").prop("disabled")

      if (not_connected == true){
        console.log("try to reconnect")
        await this.roomController.tryConnect()
      } else {
        console.log("already connected")
        await Devices.loadSpeakers()
      }

      if (currentSpeaker){
        // if (localTracks.audioTrack) {localTracks.audioTrack.setPlaybackDevice(currentSpeaker.deviceId);}
        for (var key in remoteUsers) {
          if (remoteUsers[key].audioTrack){
            remoteUsers[key].audioTrack.setPlaybackDevice(currentSpeaker.deviceId)
            .catch(error => {
              console.error("could not setPlaybackDevice 1" + error.message)
              // alert(error.message)
            })
          }
        }
      }

    };
  }

  bindOnMicrophoneChange(){
    AgoraRTC.onMicrophoneChanged = async (info) => {
      console.log("MIC CHANGED")
      var old_mic = currentMic;
      var not_connected = $("#join_room").prop("disabled")

      if (not_connected == true){
        console.log("try to reconnect")
        await this.roomController.tryConnect()
      } else {
        console.log("already connected")
        await Devices.loadMics(old_mic)
      }

      if (currentMic == undefined){
        // dont do anything
      } else if (old_mic == undefined && currentMic != undefined){
        LocalStream.update()
        if (localTracks.audioTrack && !Room.is_iOS()) { localTracks.audioTrack.setDevice(currentMic.deviceId); }
      } else if (old_mic.deviceId != currentMic.deviceId) {
        LocalStream.update()
        if (localTracks.audioTrack && !Room.is_iOS()) { localTracks.audioTrack.setDevice(currentMic.deviceId); }
      } else {
        console.log("DONT UPDATE - Theyre the same")
      }
    };
  }


  bindOnCameraChange(){
    AgoraRTC.onCameraChanged = async (info) => {
      console.log("VIDEO CHANGED");
      var old_cam = currentCam;
      var not_connected = $("#join_room").prop("disabled")

      if (not_connected == true){
        console.log("try to reconnect")
        await this.roomController.tryConnect()
      } else {
        console.log("already connected")
        await Devices.loadCameras(old_cam)
      }


      if (currentCam == undefined){
        // dont do anything
      } else if (old_cam == undefined && currentCam != undefined){
        LocalStream.update()
        if (localTracks.videoTrack && !Room.is_iOS()) { localTracks.videoTrack.setDevice(currentCam.deviceId); }
      } else if (old_cam.deviceId != currentCam.deviceId) {
        LocalStream.update()
        if (localTracks.videoTrack && !Room.is_iOS()) { localTracks.videoTrack.setDevice(currentCam.deviceId); }
      } else {
        console.log("DONT UPDATE - Theyre the same")
      }

    };
  }

}
