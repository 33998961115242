export default class FixVideos {

  constructor() {
   }

   async maximize(uid){
     this.setNormal()
     maximized_uid = uid;

     $(`#player-${uid}`).prependTo("#players_container");

     var total_videos = $("#players_container .player").length;
     var rows = total_videos - 1;
     var cols = 2;
     if (rows > 3){ cols = 1; }

     $(`.player`).removeClass("shadow-lg")
     $(`.player`).css("position", "relative").css("bottom", "").css("height", "").css("width", "").css("z-index", "").css("right", "")

     if (total_videos == 2){

       $("#players_container").removeClass("grid-rows-3 grid-rows-2 grid-cols-8 grid-rows-3 grid-rows-4 grid-rows-5 grid-rows-6 grid-rows-7 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 gap-2 grid-cols-8 grid-rows-1")
       $("#players_container").addClass(`grid-cols-1 grid-rows-1`)
       $(".player").addClass(`col-span-1 row-span-1`)
       $(".player").css("height", "")
       $(`#player-${uid} video`).parent().css("height", "100%")
       $("video").parent().css("height", "")
       $(`video`).parent().css("max-height","100%")
       $(`video`).parent().css("position","")
       $(`video`).css("position", "absolute")

       $(`.player:not(#player-${uid})`).addClass("shadow-lg")
       $(`.player:not(#player-${uid})`).css("position", "absolute").css("bottom", "20px").css("height", "30%").css("width", "20%").css("z-index", "99999").css("right", "20px")

     }else if (total_videos > 1){
       $("#players_container").removeClass("grid-rows-3 grid-rows-2 grid-cols-8 grid-rows-3 grid-rows-4 grid-rows-5 grid-rows-6 grid-rows-7 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 gap-2 grid-cols-8 grid-rows-1")
       $("#players_container").addClass(`grid-cols-8 grid-rows-${rows}`)
       $(".player").addClass(`col-span-${cols} row-span-1`)
       $(".player").css("height", "")
       $(`#player-${uid}`).removeClass(`col-span-${cols} row-span-1`).addClass(`col-span-${8 - cols} row-span-${rows}`)
       $(".player.col-span-2 video").parent().css("height", "")
       $(`#player-${uid} video`).parent().css("height", "100%")

       // $("#players_container").addClass("grid-cols-8 grid-rows-3")
       // $(".player").addClass("col-span-2 row-span-1")
       // $(".player").css("height", "")
       // $(`#player-${uid}`).removeClass("col-span-2 row-span-1").addClass("col-span-6 row-span-3")
       // $(".player.col-span-2 video").parent().css("height", "")
       // $(`#player-${uid} video`).parent().css("height", "100%")

       $(`video`).parent().css("max-height","100%")
       $(`video`).parent().css("position","")
       $(`video`).css("position", "absolute")
     } else {
       this.setNormal()
     }
   }

   setNormal(){
     maximized_uid = undefined;
     $(`.player`).removeClass("shadow-lg")
     $(`.player`).css("position", "relative").css("bottom", "").css("height", "").css("width", "").css("z-index", "").css("right", "")

     $("#players_container").removeClass("grid-rows-3 grid-rows-2 grid-cols-8 grid-rows-3 grid-rows-4 grid-rows-5 grid-rows-6 grid-rows-7")
     $(".player").removeClass("inline-flex items-center justify-self-end justify-self-center col-span-1 col-span-2 col-span-6 col-span-7 row-span-1 row-span-2 row-span-3 row-span-4 row-span-5 row-span-6 row-span-7")
     $(".player").css("height", "100%")
     $(`video`).parent().css("height", "100%").css("width", "100%")

     this.fix_resolution()
     // $("#players_container").addClass("grid-cols-2")
   }

   async showTrueFrame(uid){
     console.log("SHWOINT TRUE RAME ----------")
     $(`#player-${uid} video`).parent().css("height", "").css("width", "").css("max-height", "100%")
     // $(`#player-${uid} video`).css("height", "100%").css("width", "")
     $(`#player-${uid} video`).parents(".player").addClass("inline-flex items-center")
     $(`#player-${uid} video`).css("position", "relative")
     $(`#player-${uid} video`).parent().css("position", "relative")

     var total_videos = $("#players_container .player").length;
     var player_index = $(`#player-${uid}`).index();
     // console.log(total_videos)
     // console.log(player_index)
     if (total_videos == 3 || total_videos == 4){
       if (player_index == 0 || player_index == 2){
         $(`#player-${uid} video`).parents(".player").addClass('justify-self-end')
       }
     }

     if (total_videos == 5 || total_videos == 6){
       if (player_index == 0 || player_index == 3){
         $(`#player-${uid} video`).parents(".player").addClass('justify-self-end')
       }
       if (player_index == 1 || player_index == 4){
         $(`#player-${uid} video`).parents(".player").addClass('justify-self-center')
       }
     }

     if (total_videos > 6){
       if (player_index == 0 || player_index == 3 || player_index == 6){
         $(`#player-${uid} video`).parents(".player").addClass('justify-self-end')
       }
       if (player_index == 1 || player_index == 4 || player_index == 7){
         $(`#player-${uid} video`).parents(".player").addClass('justify-self-center')
       }
     }
     // if ( $(`#player-${uid} video`).index() == )
     // $(`#player-${uid} video`).parents(".player").addClass('justify-self-end')
   }

   removeTrueFrame(uid){
     $(`#player-${uid} video`).parent().css("height", "100%").css("width", "100%")
     $(`#player-${uid} video`).parents(".player").removeClass("inline-flex items-center justify-self-end justify-self-center")
   }

  fix_resolution(){
    if (typeof live_transcoding !== 'undefined') {
      if (live_transcoding){ Live.setTranscoding() }
    }

    if ($("#players_container .player").length == 0){
      // do nothing
    }else if ($("#players_container .player").length == 1){
      this.fix_for_one_video()
    }else if ($("#players_container .player").length == 2){
      this.fix_for_two_videos()
    }else if ($("#players_container .player").length == 3){
      this.fix_for_three_videos()
    }else if ($("#players_container .player").length == 4){
      this.fix_for_four_videos()
    }else if ($("#players_container .player").length == 5 || $("#players_container .player").length == 6){
      this.fix_for_six_videos()
    }else if ($("#players_container .player").length == 7 || $("#players_container .player").length == 8 || $("#players_container .player").length == 9){
      this.fix_for_nine_videos()
    } else {
      console.log("TOO MANY PEOPLE")
    }

    if (typeof localTracks !== 'undefined') {
      if (localTracks.videoTrack && !Room.is_iOS()) {
        if (localTracks.videoTrack._enabled) {
          // console.log("set " + this.get_resolution())
          localTracks.videoTrack.setEncoderConfiguration(this.get_resolution())
        }
      }
    }

  }

  get_resolution(){
    var reso = "720p_2" //1280 x 720

    if ($("#players_container video").length == 0){
      // do nothing
    }else if ($("#players_container video").length == 1){
      reso = {frameRate: 24, width: 848, height: 480}//1280 × 720 30
    }else if ($("#players_container video").length == 2){
      reso = {frameRate: 24, width: 640, height: 360}  //"480p_9"
    }else if ($("#players_container video").length == 3){
      reso = {frameRate: 24, width: 480, height: 280}  //"480p_2"
    }else if ($("#players_container video").length == 4){
      reso = {frameRate: 24, width: 320, height: 180}  //"360p_1"
    }else if ($("#players_container video").length == 5 || $("#players_container video").length == 6){
      reso = {frameRate: 24, width: 240, height: 140}  //"360p_7"
    }else if ($("#players_container video").length == 7 || $("#players_container video").length == 8 || $("#players_container video").length == 9){
      reso = {frameRate: 24, width: 160, height: 120} //"240p_1"
    } else {
      console.log("TOO MANY PEOPLE")
      reso = {frameRate: 15, width: 160, height: 120} //"120p_1"
    }

    return reso
  }

  fix_for_one_video(){
    $("#players_container").removeClass().addClass("custom_height p-2 w-full")
    $(".agora_video_player").css("position", "relative")
    $(".agora_video_player").parent().css("background-color", "rgb(237 242 246 / 0%)")
    $(".agora_video_player").addClass("rounded-lg object-cover")
  }

  fix_for_two_videos(){
    $("#players_container").removeClass().addClass("custom_height p-2 w-full grid grid-cols-1 grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 gap-2")
    $("#players_container video:nth-child(1), #players_container video:nth-child(3)").first().addClass("justify-self-end")
    $(".agora_video_player").parent().css("background-color", "rgb(237 242 246 / 0%)")
    $(".agora_video_player").addClass("rounded-lg object-cover")
  }

  fix_for_three_videos(){
    $("#players_container").removeClass().addClass("custom_height p-2 w-full grid-flow-row grid grid-cols-2 grid-rows-2 gap-1")
    $("#players_container video:nth-child(1), #players_container video:nth-child(3)").first().addClass("justify-self-end")
    $(".agora_video_player").parent().css("background-color", "rgb(237 242 246 / 0%)")
    $(".agora_video_player").addClass("rounded-lg object-cover")
  }

  fix_for_four_videos(){
    $("#players_container").removeClass().addClass("custom_height p-2 w-full grid-flow-row grid grid-cols-2 grid-rows-2 gap-1")
    $("#players_container video:nth-child(1), #players_container video:nth-child(3)").first().addClass("justify-self-end")
    $(".agora_video_player").parent().css("background-color", "rgb(237 242 246 / 0%)")
    $(".agora_video_player").addClass("rounded-lg object-cover")
  }

  fix_for_six_videos(){
    $("#players_container").removeClass().addClass("custom_height p-2 w-full grid-flow-row grid grid-cols-3 grid-rows-2 gap-1")
    $("#players_container video:nth-child(1), #players_container video:nth-child(3)").first().addClass("justify-self-end")
    $(".agora_video_player").parent().css("background-color", "rgb(237 242 246 / 0%)")
    $(".agora_video_player").addClass("rounded-lg object-cover")
  }

  fix_for_nine_videos(){
    $("#players_container").removeClass().addClass("custom_height p-2 w-full grid-flow-row grid grid-cols-3 grid-rows-3 gap-1")
    $("#players_container video:nth-child(1), #players_container video:nth-child(3)").first().addClass("justify-self-end")
    $(".agora_video_player").parent().css("background-color", "rgb(237 242 246 / 0%)")
    $(".agora_video_player").addClass("rounded-lg object-cover")
  }


  show_mute_attendee_icon(id){
    $("#user-name-"+id +" .name .fa-microphone-slash").remove()
    var icon = `<i style="min-width: 22px;font-size:13px;vertical-align: middle;margin-right:2px;margin-left:-2px;margin-top: -4px;" class="fas fa-microphone-slash"></i>`
    $("#user-name-"+id + " .name").prepend(icon)
    $("#user-name-"+id).css("background-color", "#ff0000c2")
  }

  hide_mute_attendee_icon(id){
    $("#user-name-"+id +" .name .fa-microphone-slash").remove()
    $("#user-name-"+id).css("background-color", "")
  }

  async update_attendee_name(id, name){
    if (name.includes("(screenshare)")) {
      if (!screenShareUsers.includes(parseInt(id))){
        screenShareUsers.push(parseInt(id))
      }

      var screen_client_uid = (screenClient || {}).uid;
      if (screen_client_uid == id){
        $(`#user-row-${id}`).find(".slider-thumb").css("visibility", "hidden");
      }
      await this.maximize(id)
      await this.showTrueFrame(id)
      $(`#player-${id}`).parent().find(".true_frame_option").hide()
      $(`#player-${id}`).parent().find(".remove_true_frame_option").show()
      $(`#player-${id}`).parent().find(".maximize_option").hide()
      $(`#player-${id}`).parent().find(".minimize_option").show()
      $(`#user-row-${id}`).find(".user_device_details, .user_option_button").hide()
    }

    if ($("#user-name-"+id +" .name .fa-microphone-slash").length){
      $("#user-name-"+id + " .name").text(name)
      this.show_mute_attendee_icon(id)
      if (name == ""){ name = "Unnamed Guest"}
      $("#user-row-"+id + " .name").text(name)
    } else {
      $("#user-name-"+id + " .name").text(name)
      if (name == ""){ name = "Unnamed Guest"}
      $("#user-row-"+id + " .name").text(name)
    }
  }


  update_meta(id, selected_camera, selected_microphone, resolution, fps, echo_cancel, user_type, microphones, cameras, camera_on, mic_on){
    // if (user_type != "Guest"){
    if (user_type == "Screenshare"){
        $("#user-row-"+id + " .user_type").remove()
    }
      $("#user-row-"+id + " .user_type").text(user_type)
    // }
    $("#user-row-"+id + " .camera").text(selected_camera)
    $("#user-row-"+id + " .camera").attr("title", selected_camera)
    $("#user-row-"+id + " .microphone").text(selected_microphone)
    $("#user-row-"+id + " .microphone").attr("title", selected_microphone)

    if (selected_camera != "Camera Off" && fps){
      $("#user-row-"+id + " .res_details").show()
      $("#user-row-"+id + " .resolution").text(resolution).show()
      $("#user-row-"+id + " .fps").text(fps)
    } else {
      $("#user-row-"+id + " .res_details").hide()
    }

    if (!resolution || resolution == "p" || $("#subscription_id").attr("data-max-width") == 0){
      $("#user-row-"+id + " .resolution").hide()
    }

    if (selected_camera == "Camera Off"){
      PlayerOptions.addCameraOffIcon($(`#player-${id}`))
    } else {
      $(`#player-${id}`).find(".video_camera_off_icon").remove()
    }

    if (selected_microphone != "Mic Muted"){
      $("#user-row-"+id + " .mute_button").show()
      $("#user-row-"+id + " .unmute_button").hide()
    } else {
      $("#user-row-"+id + " .mute_button").hide()
      $("#user-row-"+id + " .unmute_button").show()
    }

    if (echo_cancel){
      $("#user-row-"+id + " .enable_echo_cancellation_button").hide()
      $("#user-row-"+id + " .disable_echo_cancellation_button").css("display", "block")

    } else {
      $("#user-row-"+id + " .enable_echo_cancellation_button").css("display", "block")
      $("#user-row-"+id + " .disable_echo_cancellation_button").hide()
    }

    if (cameras){
      try {
        // console.log(cameras)
        //first try to parse
      JSON.parse(cameras)
      $("#user-row-"+id + " #remote_camera_settings").html("")

      JSON.parse(cameras).forEach(camera => {
        $("#user-row-"+id + " #remote_camera_settings").append(`<option value="${camera.label}">${camera.label}</option>`);
      });
      $("#user-row-"+id + " #remote_camera_settings").append(`<option value="off">Camera off</option>`);

      if (selected_camera != "Camera Off" && camera_on){
        $("#user-row-"+id + " #remote_camera_settings").val(selected_camera);
        $("#user-row-"+id + " .fa-video-slash").hide();
        $("#user-row-"+id + " .fa-video").show();
      } else {
        $("#user-row-"+id + " #remote_camera_settings").val("off");
        $("#user-row-"+id + " .fa-video-slash").show();
        $("#user-row-"+id + " .fa-video").hide();
      }

    } catch (error){
      console.log(error)
      Rollbar.error("couldn_set_cameras")
    }


   }

    if (microphones){
      try {
        // console.log(microphones)
        //first try to parse
        JSON.parse(microphones)
        $("#user-row-"+id + " #remote_microphone_settings").html("")

      JSON.parse(microphones).forEach(microphone => {
        $("#user-row-"+id + " #remote_microphone_settings").append(`<option value="${microphone.label}">${microphone.label}</option>`);
      });
      $("#user-row-"+id + " #remote_microphone_settings").append(`<option value="off">Microphone off</option>`);

      if (selected_microphone != "Mic Muted" && mic_on){
        $("#user-row-"+id + " #remote_microphone_settings").val(selected_microphone);
        $("#user-row-"+id + " .mute_button").show();
        $("#user-row-"+id + " .unmute_button").hide();
      }else {
        $("#user-row-"+id + " #remote_microphone_settings").val("off");
        $("#user-row-"+id + " .mute_button").hide();
        $("#user-row-"+id + " .unmute_button").show();
      }


    } catch (error){
      console.log(error)
      Rollbar.error("couldn_set_mics")
    }
  }

  }

  update_file_counts(id, files_saved, files_uploaded, recording_status){
    var total_percent = Math.round((files_uploaded/files_saved) * 100)
    if (total_percent > 100){ total_percent = 100 }
    if (total_percent == 100 && recording_status != "Recording"){
      // $("#user-row-"+id + " .files_uploaded_progress").hide()
      $("#user-row-"+id + " .files_uploaded_progress .upload_image").removeClass("text-blue-500 dark:text-blue-400")
      $("#user-row-"+id + " .files_uploaded_progress .text").removeClass("text-blue-500 dark:text-blue-400")
    } else{
      $("#user-row-"+id + " .files_uploaded_progress").show()
      $("#user-row-"+id + " .files_uploaded_progress .upload_image").addClass("text-blue-500 dark:text-blue-400")
      $("#user-row-"+id + " .files_uploaded_progress .text").addClass("text-blue-500 dark:text-blue-400")
    }

    $("#user-row-"+id + " .files_uploaded_progress .text").text(total_percent+ "% uploaded")
  }

  recording_status(id, recording_status){
    if (recording_status == "Recording"){
      $("#user-row-"+id + " .files_uploaded_progress").show()
      $("#user-row-"+id + " .files_uploaded_progress .upload_image").addClass("text-blue-500 dark:text-blue-400")
      $("#user-row-"+id + " .files_uploaded_progress .text").addClass("text-blue-500 dark:text-blue-400")

      $("#user-row-"+id + " .color_set").addClass("font-semibold text-red-600 dark:text-red-700")
      $("#user-row-"+id + " .recording_text").text("Recording")
    }else {
      $("#user-row-"+id + " .color_set").removeClass("font-semibold text-red-600 dark:text-red-700")
      $("#user-row-"+id + " .recording_text").text("Not Recording")
    }
  }

}
