export default class Devices {

  constructor() {
   }

   get microphone_config() {
     if (!currentMic) {
        return { AEC: options.echo_cancellation,
                 AGC: false,
                 ANS: options.noise_reduction,
                 encoderConfig: this.audio_config }
      } else {
        return { microphoneId: currentMic.deviceId,
                AEC: options.echo_cancellation,
                AGC: false,
                ANS: options.noise_reduction,
                encoderConfig: this.audio_config }
     }

    //  else if (currentMic.deviceId == "default") {
    //   return { AEC: options.echo_cancellation,
    //            AGC: false,
    //            ANS: options.echo_cancellation,
    //            encoderConfig: this.audio_config }
    // }
   }

   get audio_config(){
     return { sampleRate: 48000, stereo: false, bitrate: 128 }
   }

  async switchCamera(label) {
    var attendee_id = $("#attendee_id").val()

    if (label == "off" || label == ""){
      $("#video-btn i").addClass("dark:bg-red-900 bg-red-200 text-red-600")
      if (label == "off"){ $("#test_video_setting, #video_setting").css("opacity", "0.5") }
      // $("#test_video_setting [value='off']").text("Camera Off")
      $("#video-btn span").text("Show Camera")
      $(`#user-row-${options.uid} .fa-video-slash`).show()
      $(`#user-row-${options.uid} .fa-video`).hide()
      $("#video-btn .fa-video").removeClass("fa-video").addClass("fa-video-slash")
      $(`#player-${options.uid} .fa-video`).removeClass("fa-video").addClass("fa-video-slash")
      $(`#toggle_video .fa-video`).removeClass("fa-video").addClass("fa-video-slash")


      if (!Room.is_iOS()){
        if (local_media_stream) {
          if (local_media_stream.getVideoTracks().length > 0){
            local_media_stream.getVideoTracks()[0].enabled = false
          }
        }
      }
      if (localTracks.videoTrack) {
        // if (client.uid){ await client.unpublish(localTracks.videoTrack) }
        await localTracks.videoTrack.setEnabled(false)
      }
      // $(`#player-${options.uid} video`).parent().remove()
      // $(`#user-name-${options.uid}`).hide()
      // $(`#player-${options.uid}`).hide()
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/camera_off.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
    }else {
      $("#video-btn i").removeClass("dark:bg-red-900 bg-red-200 text-red-600")
      $("#test_video_setting, #video_setting").css("opacity", "1")
      $("#video-btn span").text("Stop Camera")
      // $("#test_video_setting [value='off']").text("Turn Off")
      $(".settings_option .fa-video-slash, #join_modal .fa-video-slash").removeClass("text-red-500").addClass("text-indigo-500")
      $("#video-btn .fa-video-slash").removeClass("fa-video-slash").addClass("fa-video")
      $(`#player-${options.uid} .fa-video-slash`).removeClass("fa-video-slash").addClass("fa-video")
      $(`#user-row-${options.uid} .fa-video-slash`).hide()
      $(`#user-row-${options.uid} .fa-video`).show()
      $(`#toggle_video .fa-video-slash`).removeClass("fa-video-slash").addClass("fa-video")
      currentCam = cams.find(cam => cam.label === label);
      if (!Room.is_iOS()){ await LocalStream.update() } //update later
      if (!localTracks.videoTrack){
        await AgoraRTC.createCameraVideoTrack({cameraId: currentCam.deviceId})
        .then(track => { localTracks.videoTrack = track })
        .catch(error => {
          console.log("could not create video track")
          console.log(error.message)
          alert(error.message)
        })
      }
      if (!localTracks.videoTrack._enabled){
        await localTracks.videoTrack.setEnabled(true)
        if (client.uid){
          // localTracks.videoTrack.play(`player-${options.uid}`);
          if (client.uid){ await client.publish(localTracks.videoTrack) }
        }
        Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/camera_on.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
      }
      if (!Room.is_iOS()){
        await localTracks.videoTrack.setDevice(currentCam.deviceId);
      }
      if (Room.is_iOS()){ await LocalStream.update() } //update after if iOS
      $(`#user-name-${options.uid}`).show()
      $(`#player-${options.uid}`).show()
    }

    $(`#test_video_setting, #video_setting, #user-row-${options.uid} .remote_camera_settings`).val(label)
    FixVideos.fix_resolution();
    Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/update_meta.js?camera=${label}`, type: "patch"})
  }

  async switchMicrophone(label) {
    // DISABLE
    var attendee_id = $("#attendee_id").val()
    $(`#test_microphone_setting, #microphone_setting, #user-row-${options.uid} .remote_microphone_settings`).val(label)

    if (label == "off" || label == ""){
      $("#mic-btn i").addClass("dark:bg-red-900 bg-red-200 text-red-600")
      if (label == "off"){ $("#test_microphone_setting, #microphone_setting").css("opacity", "0.5") }
      // $("#test_microphone_setting [value='off']").text("Microphone Off")
      $(".settings_option .fa-microphone, #join_modal .fa-microphone").addClass("text-red-500").removeClass("text-indigo-500")
      $("#mic-btn i, .settings_option .fa-microphone, #join_modal .fa-microphone").addClass("fa-microphone-slash").removeClass("fa-microphone")

      if (local_media_stream) {
        if (local_media_stream.getAudioTracks().length > 0){
          local_media_stream.getAudioTracks()[0].enabled = false;
        }
      }

      if (localTracks.audioTrack) {
        // if (client.uid){ await client.unpublish(localTracks.audioTrack) }
        await localTracks.audioTrack.setEnabled(false);
      }
      Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/mute.js`, type: "patch"})
    }else {
      $("#mic-btn i").removeClass("dark:bg-red-900 bg-red-200 text-red-600")
      $("#test_microphone_setting, #microphone_setting").css("opacity", "1")
      // $("#test_microphone_setting [value='off']").text("Turn Off")
      $(".settings_option .fa-microphone-slash, #join_modal .fa-microphone-slash").removeClass("text-red-500").addClass("text-indigo-500")
      $("#mic-btn i, .settings_option .fa-microphone-slash, #join_modal .fa-microphone-slash").addClass("fa-microphone").removeClass("fa-microphone-slash")
      currentMic = mics.find( mic => mic.label === label);
      if (localTracks.audioTrack) {
        await localTracks.audioTrack.setEnabled(false); //fixes concurrent issue with firefox
      }


      if (!Room.is_iOS()){ await LocalStream.update();  } //update later
      if (!localTracks.audioTrack){
        await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config)
        .then(track => { localTracks.audioTrack = track;
          // if (currentSpeaker){ localTracks.audioTrack.setPlaybackDevice(currentSpeaker.deviceId) }
        })
        .catch(error => {
          console.error("could not create mic" + error.message)
          alert(error.message)
        })
      }
      if (!Room.is_iOS()){
        if (localTracks.audioTrack && currentMic){
          await localTracks.audioTrack.setDevice(currentMic.deviceId);
        }
      }
      if (!localTracks.audioTrack._enabled){
        await localTracks.audioTrack.setEnabled(true)
        if (client.uid){ await client.publish(localTracks.audioTrack) }
      }
      if (Room.is_iOS()){ await LocalStream.update();  } //update after if iOS
      Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/unmute.js`, type: "patch"})
    }
    Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/update_meta.js?microphone=${label}`, type: "patch"})
  }

  async switchSpeaker(label) {
    await this.loadSpeakers() //must reload in case they have changed. when changing system default, speakers dont get updated
    $("#test_speaker_setting, #speaker_setting").val(label)

    if (label == "off"){
      $("#speaker_setting, #test_speaker_setting").val("off")
      $("#test_speaker_setting, #speaker_setting").css("opacity", "0.5")
      $(".settings_option .fa-headphones, #join_modal .fa-headphones").addClass("text-red-500").removeClass("text-indigo-500")

      if (localTracks.audioTrack){ await localTracks.audioTrack.setVolume(0) }
      for (var key in remoteUsers) {
        if (remoteUsers[key].audioTrack){ remoteUsers[key].audioTrack.setVolume(0) }
      }

    }else {
      $("#test_speaker_setting, #speaker_setting").css("opacity", "1")
      $(".settings_option .fa-headphones, #join_modal .fa-headphones").removeClass("text-red-500").addClass("text-indigo-500")
      currentSpeaker = speakers.find(speaker => speaker.label === label);
      if (currentSpeaker){
        // if (localTracks.audioTrack){ await localTracks.audioTrack.setPlaybackDevice(currentSpeaker.deviceId) }
        for (var key in remoteUsers) {
          if (remoteUsers[key].audioTrack){
            remoteUsers[key].audioTrack.setPlaybackDevice(currentSpeaker.deviceId)
            .catch(error => {
              console.error("could not setPlaybackDevice 4" + error.message)
            })
          }
        }
        $("#test_speaker_setting, #speaker_setting").val(currentSpeaker.label);
        // 100 is original volume
        if (localTracks.audioTrack){ await localTracks.audioTrack.setVolume(100) }
        for (var key in remoteUsers) {
          if (remoteUsers[key].audioTrack){ remoteUsers[key].audioTrack.setVolume(100) }
        }

      } else {
        Rollbar.error("SPEAKER_NOT_FOUND")
        // alert("Speaker couldn't be found. Contact support at support@iris.fm if you believe this incorrect.")
      }
    }
  }


  async loadMics(existingMic){
    await AgoraRTC.getMicrophones().then(microphones => {
      mics = microphones;
    console.log(mics)
    console.log(JSON.stringify(mics))
    Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?microphones=${JSON.stringify(mics)}`, type: "patch"})

    if (existingMic) { currentMic = mics.find(m => m.deviceId === existingMic.deviceId) }
    if (currentMic == undefined){ currentMic = mics[0] }

    if (cams.length > 0 && mics.length > 0){
      $("#show_permissions_modal_button").hide()
    }

    $("#test_microphone_setting, #microphone_setting").html("")
    if (currentMic == undefined){
      $("#test_microphone_setting, #microphone_setting").append(`<option value="">No Microphones Found</option>`);
      // $("#test_microphone_setting, #microphone_setting").css("color", "red");
      $("#mic-btn i").addClass("dark:bg-red-900 bg-red-200 text-red-600")
      $(".settings_option .fa-microphone, #join_modal .fa-microphone").addClass("text-red-500").removeClass("text-indigo-500")
      $("#mic-btn i, .settings_option .fa-microphone, #join_modal .fa-microphone").addClass("fa-microphone-slash").removeClass("fa-microphone")
      $("#mic-btn span").text("Unmute")
    } else {
      $("#test_microphone_setting, #microphone_setting").css("color", "")
      mics.forEach(mic => {
        $("#test_microphone_setting, #microphone_setting").append(`<option value="${mic.label}">${mic.label}</option>`);
      });
      $(`#test_microphone_setting, #microphone_setting, #user-row-${options.uid} .remote_microphone_settings`).val(currentMic.label)
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?microphone=${currentMic.label}`, type: "patch"})
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/unmute.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/disable_echo_cancellation.js`, type: "patch"})
    }
    $("#test_microphone_setting, #microphone_setting").append(`<option value="off">Microphone off</option>`);
  })
   .catch(error => {
     $("#show_permissions_modal_button").show()
   console.log(error.message)
   $("#test_microphone_setting, #microphone_setting").html("")
   $("#test_microphone_setting, #microphone_setting").append(`<option value="">Microphones Permission Denied</option>`);
   // $("#test_microphone_setting, #microphone_setting").css("color", "red");

   })
  }

  async loadCameras(existingCam){
    await AgoraRTC.getCameras().then(cameras => {
      cams = cameras
    console.log(cams)
    console.log(JSON.stringify(cams))
    Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?cameras=${JSON.stringify(cams)}`, type: "patch"})

    if (existingCam) { currentCam = cams.find(c => c.deviceId === existingCam.deviceId) }
    if (currentCam == undefined){ currentCam = cams[0] }

    if (cams.length > 0 && mics.length > 0){
      $("#show_permissions_modal_button").hide()
    }

    $("#test_video_setting, #video_setting").html("")
    if (currentCam == undefined){
      $("#test_video_setting, #video_setting").append(`<option value="">No Cameras Found</option>`);
      // $("#test_video_setting, #video_setting").css("color", "red")
      $("#video-btn i").addClass("dark:bg-red-900 bg-red-200 text-red-600")
      $(".fa-video").addClass("fa-video-slash").removeClass("fa-video")
      $(".settings_option .fa-video-slash, #join_modal .fa-video-slash").addClass("text-red-500").removeClass("text-indigo-500")
      $("#video-btn span").text("Show Camera")
    }else {
      $("#test_video_setting, #video_setting").css("color", "")
      cams.forEach(cam => {
        $("#test_video_setting, #video_setting").append(`<option value="${cam.label}">${cam.label}</option>`);
      });
      $("#test_video_setting, #video_setting").val(currentCam.label);
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?camera=${currentCam.label}`, type: "patch"})
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/camera_on.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "patch"})
  }
    $("#test_video_setting, #video_setting").append(`<option value="off">Camera off</option>`);

  })
.catch(error => {
  console.log(error.message)

  $("#test_video_setting, #video_setting").html("")
  $("#test_video_setting, #video_setting").append(`<option value="">Camera Permission Denied</option>`);
  $("#show_permissions_modal_button").show()
  // $("#test_video_setting, #video_setting").css("color", "red");
})
  }

  async loadSpeakers(){
    $("#test_speaker_setting, #speaker_setting").html("")
    await AgoraRTC.getPlaybackDevices().then(speaks => {
    speakers = speaks;

    if (currentSpeaker == undefined) {
      currentSpeaker = speakers[0];
    }

    if (currentSpeaker){
      if (!isFirefox && !isSafari){
        $(".speaker_row").show()
      }
      $("#test_speaker_setting, #speaker_setting").css("color", "")
      speakers.forEach(speaker => {
        if (speaker.deviceId == "default"){
          $("#test_speaker_setting, #speaker_setting").append(`<option value="${speaker.label}">Same as System</option>`);
        } else {
          $("#test_speaker_setting, #speaker_setting").append(`<option value="${speaker.label}">${speaker.label}</option>`);
        }
      });
      $("#test_speaker_setting, #speaker_setting").val(currentSpeaker.label);
    } else {
        $("#test_speaker_setting, #speaker_setting").append(`<option value="">No Speakers/Headphones found</option>`);
        // $("#test_speaker_setting, #speaker_setting").css("color", "red")
    }
    $("#test_speaker_setting, #speaker_setting").append(`<option value="off">Speakers off</option>`);

  })
.catch(error => {
  $("#test_speaker_setting, #speaker_setting").append(`<option value="">Can't get speakers</option>`);
  $("#test_speaker_setting, #speaker_setting").css("color", "red")
})

  }

}
