export default class VolumeMeter {

  constructor() {}

  async remoteBind(stream, uid){
    try {
      var that = this;
      var remote_audio_context = new AudioContext();
      let all_pids = $(`#user-row-${uid} .color-pids3 .thin_pid`)

      try {
        if (typeof AudioWorklet !== 'undefined'){
          await remote_audio_context.audioWorklet.addModule('https://app.iris.fm/volume_meter_worklet.js');
          const volume_meter_worklet = new AudioWorkletNode(remote_audio_context, 'VolumeMeterWorklet')
          let source = new MediaStreamAudioSourceNode(remote_audio_context, { mediaStream : stream});
          source.connect(volume_meter_worklet).connect(remote_audio_context.destination);

          volume_meter_worklet.port.onmessage = function (event) {
            that.colorRemotePids(event.data.volume * 100 * 2, all_pids);
          };
          console.log("creating AUDIOWorklet")
          return;
        }
      } catch (error) {
        console.log("cant create audioworklet")
        console.error(error);
      }
      console.log("creating AUDIO main_thread")
      var mediaStreamSource = remote_audio_context.createMediaStreamSource(stream);
      var processor = remote_audio_context.createScriptProcessor(2048, 1, 1);

      mediaStreamSource.connect(processor);
      processor.connect(remote_audio_context.destination);
      processor.onaudioprocess = function (e) {
          var inputData = e.inputBuffer.getChannelData(0);
          var inputDataLength = inputData.length;
          var total = 0;

          for (var i = 0; i < inputDataLength; i++) {
              total += Math.abs(inputData[i++]);
          }

          var rms = Math.sqrt(total / inputDataLength);
          // var level_per_pid = 6.6;
          // var max_pids = 20; //some high number
          // if (remoteUsers && remoteUsers[uid]){
          //   if (remoteUsers[uid].audioTrack){
          //     max_pids = remoteUsers[uid].audioTrack._volume/ level_per_pid
          //   }
          //   console.log(uid)
          //   console.log("max_pids", max_pids)
          // }
          that.colorRemotePids(rms * 100 * 2, all_pids);
      };
    } catch (e) {
      if (e instanceof NotSupportedError) {
        console.log("cant create anymore audio contexts") //Failed to construct 'AudioContext': The number of hardware contexts provided (6) is greater than or equal to the maximum bound (6)
      } else { throw e }
   }
  }

  colorRemotePids(vol, all_pids) {
    var amount_of_pids = Math.round((vol * 15)/100); //15 for number of pids
    // if (amount_of_pids > max_pids) { amount_of_pids = max_pids; }
    let elem_range1 = all_pids.slice(0, amount_of_pids)

    for (var i = 0; i < 15; i++) {
      all_pids[i].style.display="none";
    }

    var elem_length = elem_range1.length
    for (var i = 0; i < elem_length; i++) {
      elem_range1[i].style.display="inline-block";
    }
  }

  async bind(stream){
    var that = this;
    local_audio_context = new AudioContext();
    let all_pids1 = $('.color-pids1 .pid');
    let all_pids2 = $('.color-pids2 .pid');
    let all_pids3 = $('.color-pids3 .thin_pid');


    try {
      if (typeof AudioWorklet !== 'undefined'){
        await local_audio_context.audioWorklet.addModule('https://app.iris.fm/volume_meter_worklet.js');
        const volume_meter_worklet = new AudioWorkletNode(local_audio_context, 'VolumeMeterWorklet')
        let source = new MediaStreamAudioSourceNode(local_audio_context, { mediaStream : stream});
        source.connect(volume_meter_worklet).connect(local_audio_context.destination);

        volume_meter_worklet.port.onmessage = function (event) {
          that.colorPids(event.data.volume * 100 * 2, all_pids1, all_pids2, all_pids3);
        };
        console.log("createted AUDIO WORKLET")
        return;
      }
    } catch (error) {
      console.log("cant create audioworklet")
      console.error(error);
    }
    console.log("creating AUDIO main thread")

    var mediaStreamSource = local_audio_context.createMediaStreamSource(stream);
    var processor = local_audio_context.createScriptProcessor(2048, 1, 1);
    // mediaStreamSource.connect(audioContext.destination);
    mediaStreamSource.connect(processor);
    processor.connect(local_audio_context.destination);

    processor.onaudioprocess = function (e) {
        var inputData = e.inputBuffer.getChannelData(0);
        var inputDataLength = inputData.length;
        var total = 0;

        for (var i = 0; i < inputDataLength; i++) {
            total += Math.abs(inputData[i++]);
        }

        var rms = Math.sqrt(total / inputDataLength);

        // var level_per_pid = 6.6;
        // var max_pids = local_media_stream.getAudioTracks()[0].volume / level_per_pid
        that.colorPids(rms * 100 * 2, all_pids1, all_pids2, all_pids3);
    };
  }

  colorPids(vol, all_pids1, all_pids2, all_pids3) {
    var amount_of_pids = Math.round((vol * 15)/100); //15 for number of pids
    // if (amount_of_pids > max_pids) { amount_of_pids = max_pids; }
    let elem_range1 = all_pids1.slice(0, amount_of_pids)
    let elem_range2 = all_pids2.slice(0, amount_of_pids)
    let elem_range3 = all_pids3.slice(0, amount_of_pids)

    for (var i = 0; i < 15; i++) {
      all_pids1[i].style.display="none";
      if (all_pids2[i]){
        all_pids2[i].style.display="none";
      }
      if (all_pids3[i]){
        all_pids3[i].style.display="none";
      }
    }

    var elem_length = elem_range1.length
    for (var i = 0; i < elem_length; i++) {
      elem_range1[i].style.display="inline-block";
      if (elem_range2[i]){
        elem_range2[i].style.display="inline-block";
      }
      if (elem_range3[i]){
        elem_range3[i].style.display="inline-block";
      }
    }
  }

}
