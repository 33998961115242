export default class PlayerOptions {

  constructor() {
   }

   addOption(view_player){
     view_player.addClass("hover-trigger")
     if (view_player.find(".view_options").length == 0) {
       view_player.prepend(`
         <a data-action="room#showViewOptions" class="fas fa-ellipsis-h shadow open_popup hidden hover-target text-xs cursor-pointer tracking-wide px-2 py-1 rounded-md bg-indigo-700 bg-opacity-90 hover:bg-opactiy-100" style="font-size:16px;margin-left: 8px;z-index: 1;top: 10px;right:10px;position: absolute;color: white;">
         </a>`)
       view_player.prepend(`
         <div class="view_options hidden popup_opened shadow rounded-md bg-white" style="text-align:left;margin-left: 8px;z-index: 10;top: 38px;right:10px;position: absolute;">
           <a data-action="room#maximizePlayer" class="hover:bg-gray-200 rounded-md maximize_option block text-sm cursor-pointer tracking-wide pl-4 w-36 py-2" >
            <i class="fas fa-expand-arrows-alt mr-3"></i>Maximize
           </a>
           <div data-action="click->room#minimizePlayer" class="hover:bg-gray-200 rounded-md minimize_option hidden block text-sm cursor-pointer tracking-wide pl-4 w-36 py-2" >
            <i class="fas fa-compress-arrows-alt mr-3"></i>Minimize
           </div>
           <a data-action="room#showTrueFrame" class="hover:bg-gray-200 true_frame_option rounded-md block text-sm cursor-pointer tracking-wide pl-4 w-36 py-2">
            <i class="fas fa-expand mr-3"></i>True Frame
           </a>
           <div data-action="click->room#removeTrueFrame" class="hover:bg-gray-200 rounded-md remove_true_frame_option hidden block text-sm cursor-pointer tracking-wide pl-4 w-36 py-2">
            <svg class=" w-3 inline-block" style="margin-right:10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="expand-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-expand-alt fa-w-14 fa-3x"><path fill="currentColor" d="M212.686 315.314L120 408l32.922 31.029c15.12 15.12 4.412 40.971-16.97 40.971h-112C10.697 480 0 469.255 0 456V344c0-21.382 25.803-32.09 40.922-16.971L72 360l92.686-92.686c6.248-6.248 16.379-6.248 22.627 0l25.373 25.373c6.249 6.248 6.249 16.378 0 22.627zm22.628-118.628L328 104l-32.922-31.029C279.958 57.851 290.666 32 312.048 32h112C437.303 32 448 42.745 448 56v112c0 21.382-25.803 32.09-40.922 16.971L376 152l-92.686 92.686c-6.248 6.248-16.379 6.248-22.627 0l-25.373-25.373c-6.249-6.248-6.249-16.378 0-22.627z" class=""></path></svg>
            Zoom Frame
           </div>
         </div>`)
     }

   }

   addName(view_player, uid){
     if (view_player.find(`#user-name-${uid}`).length == 0){
       view_player.prepend(`<div id="user-name-${uid}" class="tracking-wide px-2 rounded-md bg-gray-900 bg-opacity-75" style="left: 6px;margin-left: 5px;z-index: 2;bottom: 10px;position: absolute;color: white;display: inline-block;">
       <span class="options hidden pr-1"><i class="fas fa-ellipsis-h"></i></span>
       <span class="name"></span>
       </div>`)
     }

   }

   addCameraOffIcon(div){
     if (div.find(".video_camera_off_icon").length == 0){
       var icon = `<i class="video_camera_off_icon fas p-2 bg-gray-700 rounded-full text-gray-200 fa-video-slash"
                    style="width: 100px;height: 100px;padding-top: 35px;padding-left: 11px;font-size: 32px;position: absolute;top: 50%;left: 50%;margin: -50px 0 0 -50px;"></i>`
       div.append(icon);
     }
   }

}
