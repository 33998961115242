export default class LocalStream {

  constructor() {
   }

   async save_blob(blob, index, current_recording_id, tries = 0){
     db.blobs.count().then(function(count){
       $("#current_count_of_files_left_to_upload").text(count + 1)
     })

     var recording_id = current_recording_id
     if (typeof recording_id == 'undefined'){ recording_id = parseInt($("[data-recording-id]").attr("data-recording-id")) }
     var attendee_id = $("#attendee_id").val();
     var blob_data = await new Response(blob).arrayBuffer();

     var that = this;
     db.blobs.put({ filename: index+".webm", blob_data: blob_data, recording_id: recording_id, attendee_id: attendee_id}).then (function(record){
       console.log(`inserted ${index+".webm"} into the local db`)
     }).catch(function(error) {
       try{
       navigator.storage.estimate().then(function(data){console.log(data["usage"] + " out of "+ data["quota"] + " used.")})
     } catch (error) { }
       console.log("ERROR SAVING BLOB1: " + error + " " + tries);
       tries = tries + 1;
       if (tries < 10){
         console.log("retry saving blob...")
         console.log(index+".webm" + " total size:"+ blob_data.byteLength + " recording_id:" + recording_id +" attendee_id:"+ attendee_id)
         // Rollbar.error("ERROR SAVING BLOB (save_blob_still_retry): " + error);
         local_db.load_local_db()
         setTimeout(function(){ that.save_blob(blob, index, current_recording_id, tries);  }, 500);
       } else {
         // then just upload now

         // var file = new File([blob_data], index+".webm", {lastModified: new Date().getTime(), type: blob_data.type});
         // Rails.ajax({url: `/recordings/${recording_id}/attendee/${attendee_id}/finish_post_url.js`, type: "get",
         // success: function(){
         //     console.error("uploading right away")
         //     Rollbar.error("uploading right away");
         //     $('.directUpload').find("input:file").fileupload('add', {files: [file]});
         //     window.uploading_recording_id = recording_id
         //     window.uploading_attendee_id = attendee_id
         //   },
         //   error: function(data){
         //     console.log("failed getted post url in last ditch effort")
         //     console.log(blob)
         //     setTimeout(function(){ local_db.upload_blob(blob) }, 2000);
         //   },
         // })


         if (error.message.includes("QuotaExceededError")){
           try {
             navigator.storage.estimate().then(function(data){console.log(data["usage"] + " out of "+ data["quota"] + " used.")})
           } catch (error) { }
           Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=nospace`, type: "patch"})
           alert("You have no space avaialable on your computer for local recordings. We are unable to save your recording. ERROR SAVING BLOB: " + error);
       } else{
         console.error("ERROR SAVING BLOB1 - QUIT: " + error);
         Rollbar.error("ERROR SAVING BLOB (save_blob): " + error);
         alert("You may need to use another browser. We were unable to save files locally. ERROR SAVING BLOB: " + error);
       }
         // alert("Error with trying to save the blob. You either need to free up local space and restart the recording or update your browser. " + error)
       }
     });
   }

   async save_screen_blob(blob, index, current_recording_id, tries = 0){
     db.blobs.count().then(function(count){
       $("#current_count_of_files_left_to_upload").text(count + 1)
     })

     var recording_id = current_recording_id
     if (typeof recording_id == 'undefined'){ recording_id = parseInt($("[data-recording-id]").attr("data-recording-id")) }
     var attendee_id = options.share_uid.toString();
     var blob_data = await new Response(blob).arrayBuffer();

     var that = this;
     db.blobs.put({ filename: index+".webm", blob_data: blob_data, recording_id: recording_id, attendee_id: attendee_id}).then (function(record){
       console.log(`inserted screenshare ${index+".webm"} into the local db`)
     }).catch(function(error) {
       console.error("ERROR SAVING BLOB1: " + error);
       tries = tries + 1;
       if (tries < 3){
         console.log("retry saving blob")
         setTimeout(function(){ that.save_screen_blob(blob, index, current_recording_id, tries);  }, 3000);
       } else {
         console.error("ERROR SAVING BLOB1 - QUIT: " + error);
         alert("ERROR SAVING BLOB: (ssb)" + error)
       }
     });
   }

   async record(try_with_focus = true){
     var that = this;

     // if (webRecorder.recorder()) {
     //   if (webRecorder.recorder().state != "inactive"){
     //     webRecorder.stop();
     //   }
     // }
     //
     // if (producer_role == false && user_joined_room == true) {
     //   window.local_checker = setInterval(that.checkStillRunning, 5000);
     //   window.last_ondataavailable = new Date();
     //   this.web_record()
     // }

     if (typeof screenTracks !== 'undefined') {
       if (screenTracks.videoTrack || screenTracks.audioTrack){
         Room.record_screen_share();
       }
     }
     // return;


     // ensure no other recording is taking place
     if (recorder) {
       if (recorder.state == "recording"){ recorder.stop() }
       recorder = undefined;
     }

     var that = this;
     var bits_per_second = 2500000;
     if (current_camera_height >= 720){ bits_per_second = 5000000; }
     if (current_camera_height >= 1080){ bits_per_second = 8000000; }

     // its just too much data for the browser
     if (current_camera_height >= 2100){
       bits_per_second = 16000000;
     }

      if (producer_role == false && user_joined_room == true) {
        window.local_checker = setInterval(that.checkStillRunning, 10000);
        window.last_ondataavailable = new Date();

        if (local_media_stream && local_media_stream.getTracks().length > 2){
          console.error("more than 2 tracks?!? total tracks=" + local_media_stream.getTracks().length)
        }

        if (local_media_stream && local_media_stream.getTracks().length == 0){
          console.error("0 tracks?!? total tracks=" + local_media_stream.getTracks().length)
          Rollbar.error("no_tracks")
        }

        try {
          if ($("#subscription_id").attr("data-max-width") == 0) {
            recorder = new MediaRecorder(local_media_stream, { audioBitsPerSecond: 192000 });
          } else {
            recorder = new MediaRecorder(local_media_stream, {
               mimeType: encoding_options,
               audioBitsPerSecond: 192000,
               videoBitsPerSecond: bits_per_second,
             });
          }

        } catch(err1) {
          console.log(err1);
          console.log(`cams length ${cams.length == 0}`)
          console.log(`audio only ${$("#subscription_id").attr("data-max-width") == 0}`)
          Rollbar.error("failed_first_recording_start1");
          alert("Window needs to be focused in order to start recording. We will now start recording once you click OK.")
          try {
            await that.update()
            if ($("#subscription_id").attr("data-max-width") == 0 || cams.length == 0) {
              recorder = new MediaRecorder(local_media_stream, { audioBitsPerSecond: 192000 });
            } else {
              recorder = new MediaRecorder(local_media_stream, {
                 mimeType: encoding_options,
                 audioBitsPerSecond: 192000,
                 videoBitsPerSecond: bits_per_second,
               });
            }
          } catch(err2) {
            console.log(err2);
            Rollbar.error("failed_final_recording_start1");
          }

        }


         var current_recording_id = parseInt($("[data-recording-id]").attr("data-recording-id"))

         recorder.ondataavailable = function(e) {
           if (!e.data || !e.data.size || e.data.size < 100){
             if (recorder){
               if (recorder.state == "recording"){
                    // Room.show_error_message("Your browser tried to save a recording without any data. Please try a different browser like Google Chrome or else contact support at support@iris.fm.")
                    if (!e.data || !e.data.size){
                      console.error("NO DATA ondataavailable")
                      // setTimeout(function(){
                        Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=zerofile`, type: "patch"})
                      // }, 1000);
                    } else {
                      console.error("Too small DATA ondataavailable: "  + e.data.size)
                      // setTimeout(function(){
                        Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=toosmallfile` + e.data.size, type: "patch"})
                      // }, 1000);
                    }
              }
             }
           }else {
             window.last_ondataavailable = new Date();
             that.save_blob(e.data, blob_index, current_recording_id);
             blob_index++;
             Rails.ajax({url: `/recordings/${current_recording_id}/attendee/${$("#attendee_id").val()}/file_saved.js`, type: "patch"})
           }
         }
         recorder.onstop = function(event) {
           console.log("recorder stopped");
           console.log(event)
           // LocalStream.stopRecording()
         }
         recorder.onerror = function(event) {
           console.log("recorder error");
           console.log(encoding_options);
           console.error(`error recording stream: ${event.error.name}`);
           console.log(event.error.message)
           Rollbar.error(event.error.name)
           setTimeout(function(){
             Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=unknown`, type: "patch"})
           }, 1000);
           alert("Local Recording Error: Please contact us at support@iris.fm" +event.error)
           throw event.error;
         }
         recorder.onstart = function() {
             console.log('started');
         };
         recorder.onpause = function() {
             console.log('paused');
         };
         recorder.onresume = function() {
             console.log('resumed');
         };

         try {
           recorder.start(10000)
         } catch(err1) {
           console.log(err1);
           console.log(encoding_options)
           Rollbar.error("failed_first_recording_start2");
           alert("Window needs to be focused in order to start recording. We will now start recording once you click OK.")
           try {
             await that.update()
             recorder = new MediaRecorder(local_media_stream, {
                mimeType: encoding_options,
                audioBitsPerSecond: 192000,
                videoBitsPerSecond: bits_per_second,
              });
             recorder.start(10000)
           } catch(err2) {
             console.log(err2);
             Rollbar.error("failed_final_recording_start2");
           }
         }

         Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/recording_started.js`, type: "patch"})
         if (create_transcripts && Transcribe.running != true){ Transcribe.start() }
      }


   }

   // web_record(){
   //
   //    webRecorder.start(local_media_stream)
   //      .catch(function (error) {
   //        console.error("ERROR starting webrecorder:", error);
   //        Rollbar.error("ERROR starting webrecorder:", error);
   //        return;
   //      });
   //      window.webrecorder = webRecorder;
   //      Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/recording_started.js`, type: "patch"})
   // }

   checkStillRunning(){
     if (!recorder){
       clearInterval(window.local_checker);
       clearInterval(window.recording_interval);
       window.local_checker = undefined;
       window.recording_interval = undefined;
       Rollbar.error("checker_no_recorder");
       return;
     }

     if (recorder.state != "recording"){
       clearInterval(window.local_checker);
       clearInterval(window.recording_interval);
       window.local_checker = undefined;
       window.recording_interval = undefined;
       Rollbar.error("checker_not_recording_state");
       return;
     }

     if (!window.uploading){
       clearInterval(window.local_checker);
       clearInterval(window.recording_interval);
       window.local_checker = undefined;
       window.recording_interval = undefined;
       Rollbar.error("checker_not_recording_state");
       return;
     }

     var video_state = "live";
     var audio_state = "live";
     var error = false;

     if (recorder){
       if (recorder.stream.getVideoTracks().length > 0){
         video_state = recorder.stream.getVideoTracks()[0].readyState;
       }

       if (recorder.stream.getAudioTracks().length > 0){
         audio_state = recorder.stream.getAudioTracks()[0].readyState;
       }

       if (video_state != "live" || audio_state != "live") {
         error = true;
       }
     } else {
       // error = true;  //not sure if we need this.
     }

     var seconds_since_last_ondataavailable = ((new Date).getTime() - window.last_ondataavailable.getTime()) / 1000;

     if (error) {
       clearInterval(window.local_checker);
       clearInterval(window.recording_interval);
       console.log("check room status to see if still recording")
       Rails.ajax({url: `/r/${$("[data-room-id]").attr("data-room-id")}/status.js?recording_id=${$("#recording_id").attr("data-recording-id")}`, type: "get"})

       setTimeout(() => {
         if (recorder && recorder.state == "recording" && window.uploading){ //window.uploading means its recording locally
           console.log("show error since still recording....")
           console.error("Monitoring error: disrupted video_state:" + video_state + " audio_state:" + audio_state)
           Rollbar.error("Monitoring error: disrupted")
           Room.show_error_message("ERROR: Your local recording has been disrupted. Most likely your camera or microphone has been disconnected. Your local video and audio are no longer being saved. <br><br>You will need to rejoin the recording to continue.")
           Room.stop_showing_now_recording();
           // setTimeout(function(){
             Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=disrupted`, type: "patch"})
           // }, 1000);
           $("#stop_recording_button span, #guest_start_recording_button span").text("Recording Stopped")
         }

       }, 3000);

     }else if (seconds_since_last_ondataavailable > 60) {

         var any_working_tracks = false;
         if (localTracks.videoTrack) { if (localTracks.videoTrack._enabled){ any_working_tracks = true; } }
         if (localTracks.audioTrack) { if (localTracks.audioTrack._enabled){ any_working_tracks = true; } }

         if (any_working_tracks){
           clearInterval(window.local_checker);
           clearInterval(window.recording_interval);
           // if (seconds_since_last_ondataavailable < 75) {
             console.error("Monitoring error: notcalled time:"  + seconds_since_last_ondataavailable)
             Rollbar.error("Monitoring error: notcalled time:", seconds_since_last_ondataavailable)
           // }
           Room.show_error_message("ERROR: Your local recording is NOT being saved because of an issue with your browser. <br><br>You can try to fix this by updating your browser or using Google Chrome or you can email our support at support@iris.fm for help.")
           Room.stop_showing_now_recording();

           // setTimeout(function(){
             Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/error.js?error=notcalled`, type: "patch"})
           // }, 1000);
           // $("#stop_recording_button span, #guest_start_recording_button span").text("Recording Stopped")
         }
       } else if (seconds_since_last_ondataavailable > 20) {
         var camera_on = $("#video-btn i").hasClass('fa-video');
         var mic_on = $("#mic-btn i").hasClass('fa-microphone')
         console.log(`manually request data camera_on:${camera_on} mic_on: ${mic_on} blob_index: ${blob_index}`)
         recorder.requestData();
     }
   }

   stopRecording(){
     clearInterval(window.local_checker);
     clearInterval(window.recording_interval);
     Transcribe.stop()

     // webRecorder.stop();
     if (screenrecorder) {
       if (screenrecorder.state != 'inactive'){
         screenrecorder.stop();
       }
       Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${options.share_uid}/recording_stopped.js`, type: "patch"});
     }
     screenrecorder = undefined;

     if (recorder) {
       if (recorder.state == "recording"){ recorder.stop() }
     }
     recorder = undefined;
     Rails.ajax({url: `/recordings/${$("#recording_id").attr("data-recording-id")}/attendee/${$("#attendee_id").val()}/recording_stopped.js`, type: "patch"})
   }

   async update_iOS(){
     if (local_audio_context){
       if (local_audio_context.state == "running"){
         local_audio_context.close()
       }
     }

     if (local_media_stream){
       local_media_stream.getTracks().forEach(track => track.stop());
       local_media_stream = undefined;
     }

     if (localTracks.videoTrack && localTracks.audioTrack){
       console.log("reset both tracks")
       // localTracks.videoTrack.close();
       // localTracks.videoTrack.close();

       if (client.uid){ await client.unpublish() }

       // can these be changed after joined room?
       if (localTracks.audioTrack){
         await localTracks.audioTrack.stop();
         await localTracks.audioTrack.close();
         localTracks.audioTrack = undefined;
       }

       if (localTracks.videoTrack){
         await localTracks.videoTrack.stop();
         await localTracks.videoTrack.close();
         localTracks.videoTrack = undefined;
       }

       await navigator.mediaDevices.getUserMedia({ audio: this.audio_options, video:
         { width: { ideal: $("#subscription_id").attr("data-max-width") },
           height: { ideal: $("#subscription_id").attr("data-max-height") },
           frameRate: local_frame_rate,
           deviceId: { exact: currentCam.deviceId }} })
             .then(async function(camera) {
               localTracks.audioTrack = AgoraRTC.createCustomAudioTrack({mediaStreamTrack: camera.getAudioTracks()[0]});

               var remote_camera = camera.clone()
               await remote_camera.getVideoTracks()[0].applyConstraints({width: 640, height: 360})
               await remote_camera.getVideoTracks()[0].applyConstraints({frameRate: 24})
               localTracks.videoTrack = AgoraRTC.createCustomVideoTrack({mediaStreamTrack: remote_camera.getVideoTracks()[0], bitrateMax: 1600});

               if ($("#pre-local-player").length > 0){
                 localTracks.videoTrack.play("pre-local-player")
               }
               if (client.uid){
                 await client.publish(Object.values(localTracks))
                 await localTracks.videoTrack.play(`player-${options.uid}`)
               }

               if ($("#subscription_id").attr("data-max-width") != 0 && cams.length > 0) {
                 local_media_stream = camera;
               } else {
                 local_media_stream = new MediaStream([camera.getAudioTracks()[0]])
                }

             }).catch(async function(error) {
               // should try one more time without constraints - OverconstrainedError: Invalid constraint
               console.error("Cant get devices round for mobile", error)
               Rollbar.error("cant get mobile devices", error)
               alert(error)
             });


       if (local_media_stream){
         // NEED TO CHANGE THIS PROCESS
         if (local_media_stream.getVideoTracks()[0]) {
           this.show_video_dimensions()
         }

         VolumeMeter.bind(local_media_stream);
         if (local_media_stream.getVideoTracks()[0]){
           Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?camera=${local_media_stream.getVideoTracks()[0].label}`, type: "patch"})
         }
         Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?microphone=${local_media_stream.getAudioTracks()[0].label}`, type: "patch"})
       }
     }
     else if (localTracks.audioTrack){
       console.log("reset one track")

       if (client.uid){ if (localTracks.audioTrack){
         await client.unpublish(localTracks.audioTrack)
       } }
       if (localTracks.audioTrack){ localTracks.audioTrack.close() }

       await navigator.mediaDevices.getUserMedia({ audio: this.audio_options, video: false})
             .then(async function(tracks) {
               localTracks.audioTrack = AgoraRTC.createCustomAudioTrack({mediaStreamTrack: tracks.getAudioTracks()[0]});
               if (client.uid){ await client.publish(Object.values(localTracks)) }
               local_media_stream = tracks;
             }).catch(async function(error) {
               // should try one more time without constraints - OverconstrainedError: Invalid constraint
               console.error("Cant get devices round for mobile 2", error)
               Rollbar.error("cant get mobile devices 2", error)
               alert(error)
             });

       if (local_media_stream){
         VolumeMeter.bind(local_media_stream);
         Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?microphone=${local_media_stream.getAudioTracks()[0].label}`, type: "patch"})
       }


     }
     else if (localTracks.videoTrack){
      local_media_stream = new MediaStream([localTracks.videoTrack._originMediaStreamTrack])
      // VolumeMeter.bind(local_media_stream);
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?camera=${local_media_stream.getVideoTracks()[0].label}`, type: "patch"})
    }


    // if (local_media_stream){
    //       if (local_media_stream.getVideoTracks()[0]){
    //          webRecorder.replaceVideoTrack(local_media_stream.getVideoTracks()[0]);
    //      }
    //      if (local_media_stream.getAudioTracks()[0]){
    //         webRecorder.addAudioTrack(local_media_stream.getAudioTracks()[0]);
    //     }
    //  }

   }

   async update(retry = true){
       var that = this;

       if (Room.is_iOS()){ //Room.is_iOS()
         await this.update_iOS();
       } else {
         await this.closeExistingTracks()

       // first try exact options
       await navigator.mediaDevices.getUserMedia({ audio: this.audio_options, video: this.exact_video_options })
             .then(function(camera) { local_media_stream = camera; })
             .catch(async function(error) {
               console.log("Cant get devices round 1", error.message)
               await navigator.mediaDevices.getUserMedia({ audio: that.audio_options, video: that.video_options })
                      .then(function(camera) { local_media_stream = camera; })
                      .catch(async function(error) {
                          console.log("Cant get devices round 2", error.message)
                          if (error.toString().includes("PERMISSION_DENIED") || error.toString().includes("Permission denied")){
                            $("#no_permissions").show()
                            console.log('local_capture_failure_0 Unable to capture your camera. Please check logs.' + error);
                          }else if ( error.toString().includes("Could not start video source") ) {

                            alert('Unable to capture your camera. Check that your webcam is not in use by another application. You may need to close other applications or tabs or restart your browser to free up the camera so it can be used by Iris. Also you can click the camera icon in the address bar of your browser to ensure you have granted permissions.\n\n Error:' + error);
                            console.log('local_capture_failure_1A Unable to capture your camera. Please check logs. ' + error);
                            if (retry){
                              Devices.loadCameras()
                              var new_camera = cams.filter(cam => cam.label != currentCam.label)[0]
                              if (new_camera) {
                                console.log("retry but change video source")
                                $("#video_setting").val(new_camera.label)
                                $("#video_setting").change();
                                // that.update(false)
                              }
                            }
                            // Rollbar.error("CANT_GET_LOCAL_DEVICES_AUDIO_ONLY", error);
                            await navigator.mediaDevices.getUserMedia({ audio: that.audio_options, video: false }).then(function(camera) { local_media_stream = camera; })
                          } else {
                            await navigator.mediaDevices.getUserMedia({ audio: that.audio_options, video: { width:  1280, height: 720, frameRate: local_frame_rate } })
                            .then(function(camera) { local_media_stream = camera; })
                            .catch(async function(error) {
                              console.log("Cant get devices round 3", error.message)
                              await navigator.mediaDevices.getUserMedia({ audio: that.audio_options, video: true })
                              .then(function(camera) { local_media_stream = camera; })
                              .catch(async function(error) {
                                console.log("Cant get devices round 4:", error.message)
                                await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                                .then(function(camera) { local_media_stream = camera; })
                                .catch(async function(error) {
                                  if (cams.length != 0 && mics.length != 0 ){
                                    console.error("Cant get devices final round 5:", error.message)
                                    alert('Unable to capture all your devices Check that your devices are not in use by another application. You may need to close other applications or tabs or restart your browser to free up the device so it can be used by Iris. Also you can click the camera/microphone icon in the address bar of your browser to ensure you have granted permissions.\n\n Error:' + error);
                                    Rollbar.error("CANT_GET_LOCAL_DEVICES_FINAL", error);
                                  }else {
                                    $("#no_permissions").show()
                                    console.log("agora couldnt get anything")
                                  }

                                });
                              });
                            });
                        }
                      });
             });

           //   if (local_media_stream){
           //     if (local_media_stream.getVideoTracks()[0]){
           //        webRecorder.replaceVideoTrack(local_media_stream.getVideoTracks()[0]);
           //    }
           //    if (local_media_stream.getAudioTracks()[0]){
           //       webRecorder.addAudioTrack(local_media_stream.getAudioTracks()[0]);
           //   }
           // }

       // set some events and show data
       if (local_media_stream){
         if (local_media_stream.getVideoTracks()[0]){
           local_media_stream.getVideoTracks()[0].onended = function() { console.log("VIDEO ENDED"); that.update(); }
           local_media_stream.onended = function() { console.log("--- ENDED"); }
           local_media_stream.onremovetrack = (event) => { console.log(`${event.track.kind} track removed`); };
           that.show_video_dimensions()
           Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?camera=${local_media_stream.getVideoTracks()[0].label}`, type: "patch"})
       } else {
           $("#current_video_resolution, #current_video_fps").hide()
         }
         if (local_media_stream.getAudioTracks()[0]) {
           VolumeMeter.bind(local_media_stream);
           local_media_stream.getAudioTracks()[0].onended = function() { console.log("Audio ENDED"); that.update(); }
           Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?microphone=${local_media_stream.getAudioTracks()[0].label}`, type: "patch"})
           // console.log(local_media_stream.getAudioTracks()[0].getSettings())
         }
       }
     }

   }

    get video_options(){
      // dont use camera if width is 0
     if ($("#subscription_id").attr("data-max-width") == 0 || cams.length == 0) {
       return false;
     } else {
       if (currentCam != undefined){
         return {
           width: { ideal: $("#subscription_id").attr("data-max-width") },
           height: { ideal: $("#subscription_id").attr("data-max-height") },
           frameRate: local_frame_rate,
           deviceId: { exact: currentCam.deviceId }
         }
       }else {
         return {
           width: { ideal: $("#subscription_id").attr("data-max-width") },
           height: { ideal: $("#subscription_id").attr("data-max-height") },
           frameRate: local_frame_rate,
         }
       }
     }
   }

   get exact_video_options(){

     if ($("#subscription_id").attr("data-max-width") == 0 || cams.length == 0) {
       return false;
     } else {
        if (currentCam != undefined){
          return {
            width: { ideal: $("#subscription_id").attr("data-max-width") },
            height: { ideal: $("#subscription_id").attr("data-max-height") },
            frameRate: { exact: local_frame_rate },
            deviceId: { exact: currentCam.deviceId }
          }
        }else {
          return {
            width: { ideal: $("#subscription_id").attr("data-max-width") },
            height: { ideal: $("#subscription_id").attr("data-max-height") },
            frameRate: { exact: local_frame_rate },
          }
        }
    }
  }

   get audio_options(){
    if (currentMic != undefined) {
      return {
          deviceId: { exact: currentMic.deviceId},
          channelCount: 1,
          noiseSuppression: options.noise_reduction,
          autoGainControl: false,
          sampleRate: audio_sample_rate,
          echoCancellation: options.echo_cancellation,
        }
    } else {
      return {
          channelCount: 1,
          noiseSuppression: options.noise_reduction,
          sampleRate: audio_sample_rate,
          echoCancellation: options.echo_cancellation,
          autoGainControl: false,
        }
    }
  }

   async closeExistingTracks(){
     if (local_media_stream){
       // webRecorder.deleteAudioTrack(local_media_stream.getAudioTracks()[0]);
       local_media_stream.getTracks().forEach(track => track.stop());
     }
     local_media_stream = undefined;
     if (local_audio_context){
       if (local_audio_context.state == "running"){
         try { await local_audio_context.close() }
         catch (InvalidStateError) { console.log("InvalidStateError: Can't close an AudioContext twice ") }
       }
     }
   }

  show_video_dimensions(){
    if (local_media_stream){
      var video_track_settings = local_media_stream.getVideoTracks()[0].getSettings();
      var width = video_track_settings.width
      var height = video_track_settings.height
      var fps = video_track_settings.frameRate
      current_camera_height = height;
      $("#current_video_resolution").show().text(width + " x " +  height)
      $("#current_video_fps").show().text(fps + " fps")
      Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js?height=${height}&fps=${fps}`, type: "patch"})
    }
  }

}
