export default class AgoraChannel {

  constructor() {
   }



   async connectAgoraDevices() {
     // optimizationMode: "detail"

     // why check this if microphone_config already checks?
     if (currentMic){
       await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config)
       .then(track => {
         localTracks.audioTrack = track;
         if (currentSpeaker){
           // localTracks.audioTrack.setPlaybackDevice(currentSpeaker.deviceId)
           // .catch(error => {
           //   console.log("could not setPlaybackDevice 3" + error.message)
           // })
         }
       })
       .catch(error => {
         if (error.toString().includes("PERMISSION_DENIED") || error.toString().includes("Permission denied")){
           console.log("agora_capture_failure1A "+error.message)
         } else {
           console.error("agora_capture_failure1 "+error.message)
           // alert(error.message)
         }
       })
     }

     if (currentCam){
       await AgoraRTC.createCameraVideoTrack({cameraId: currentCam.deviceId})
       .then(track => {
         localTracks.videoTrack = track;
         localTracks.videoTrack.play("pre-local-player");
         $("#pre-local-player").click()
         localTracks.videoTrack.setEncoderConfiguration("360p_8");
       })
       .catch(error => {
         if (error.toString().includes("PERMISSION_DENIED") || error.toString().includes("Permission denied")){
           console.log("agora_capture_failure2A "+error.message)
         } else {
           console.error("agora_capture_failure2 "+error.message)
           // alert(error.message)
         }
       })

     }

     $(".agora_video_player").parent().addClass("rounded-xl").css("background-color","#ffffff00")
   }

   async handleLocalNetworkQuality(stats){
     var uid = $("#attendee_id").val()

      // console.log(uid + " " + network_qualities[uid]["uplinkNetworkQuality"] + " " + network_qualities[uid]["downlinkNetworkQuality"])
      if (stats["uplinkNetworkQuality"] == 0 && stats["downlinkNetworkQuality"] == 0){
        // do nothing - cant determine network quality
      } else if (stats["uplinkNetworkQuality"] == 1 && stats["downlinkNetworkQuality"] == 1){
        $("#user-row-"+uid + " .network").hide()
        $("#user-row-"+uid + " .network-great").css("display", "inline-block")
      } else if (stats["uplinkNetworkQuality"] < 5 && stats["downlinkNetworkQuality"] < 5){
        $("#user-row-"+uid + " .network").hide()
        $("#user-row-"+uid + " .network-okay").css("display", "inline-block")
      } else {
        $("#user-row-"+uid + " .network").hide()
        $("#user-row-"+uid + " .network-poor").css("display", "inline-block")
      }
   }


   async updateRemoteNetworkQuality() {
     var network_qualities = client.getRemoteNetworkQuality();
     for (var uid in network_qualities){
        // console.log(uid + " " + network_qualities[uid]["uplinkNetworkQuality"] + " " + network_qualities[uid]["downlinkNetworkQuality"])
        if (network_qualities[uid]["uplinkNetworkQuality"] == 0 && network_qualities[uid]["downlinkNetworkQuality"] == 0){
          // do nothing - cant determine network quality
        } else if (network_qualities[uid]["uplinkNetworkQuality"] == 1 && network_qualities[uid]["downlinkNetworkQuality"] == 1){
          $("#user-row-"+uid + " .network").hide()
          $("#user-row-"+uid + " .network-great").css("display", "inline-block")
        } else if (network_qualities[uid]["uplinkNetworkQuality"] < 5 && network_qualities[uid]["downlinkNetworkQuality"] < 5){
          $("#user-row-"+uid + " .network").hide()
          $("#user-row-"+uid + " .network-okay").css("display", "inline-block")
        } else {
          $("#user-row-"+uid + " .network").hide()
          $("#user-row-"+uid + " .network-poor").css("display", "inline-block")
        }
     }
   }

   async join() {
     Rails.ajax({url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/update_meta.js`, type: "patch"})
     if (localTracks.videoTrack){
       if (localTracks.videoTrack._enabled){
         localTracks.videoTrack.play(`player-${options.uid}`);
       } else {
         PlayerOptions.addCameraOffIcon($(`#player-${options.uid}`))
       }
     } else {
       PlayerOptions.addCameraOffIcon($(`#player-${options.uid}`))
     }
     PlayerOptions.addName($(`#player-${options.uid}`), options.uid)
     PlayerOptions.addOption($(`#player-${options.uid}`))
     FixVideos.fix_resolution()

     if (localTracks.audioTrack){
       if (!localTracks.audioTrack._enabled){
         FixVideos.show_mute_attendee_icon(options.uid)
       }
     } else {
       FixVideos.show_mute_attendee_icon(options.uid)
     }

      // setTimeout(function(){ Room.check_connection() }, 5000);
      await Room.join_channel()

     $('#name_setting').trigger('keyup');
     if ($("#show_recording").length){
       var show_already_recording = true
       Room.show_now_recording(show_already_recording);
       $("#show_recording").remove();
     }
     // $("#invite_button").click() //show invite modal by default
     if ($("#is_host").length > 0){
       setInterval(this.updateRemoteNetworkQuality, 1000);
     }
   }

   async handleUserJoined(user){
     console.log("user joined---")
     const uid = user.uid;
     if ($(`#user-row-${uid}`).length == 0 ){
       $("#participant_rows").append($("#participant_row_template").html())
       var new_user_row = $("#participant_rows li").last()
        $(new_user_row).attr("id", $(new_user_row).attr("id") + uid)
     }

     Rails.ajax({url: `/${subscription_id}/attendee/${uid}/name.js`, type: "get"})
     remoteUsers[uid] = user;
   }
   async handleUserLeft(user){
     console.log("-----------------------------------------------user left---")

     const id = user.uid;
     if (screenShareUsers.includes(id) || (!user.hasAudio && !user.hasVideo && user._audio_muted_ == false && user._video_muted_ == false)){
        $(`#player-${id}, #user-row-${id}`).remove();
        delete remoteUsers[id];

        if (id == maximized_uid){
          console.log("set normal")
          FixVideos.setNormal()
        } else if (maximized_uid){
          console.log("set maximize")
          setTimeout(function(){ FixVideos.maximize(maximized_uid) }, 500);
        }
      }
   }

    async handleUserPublished(user, mediaType) {
      const uid = user.uid;
      await client.subscribe(user, mediaType);
      console.log(`:handleUserPublished start ${user.uid} ${mediaType}`)
      // console.log(mediaType)

      //can we do this with user joined now?
       // if ($(`#user-row-${uid}`).length == 0 ){
       //   $("#participant_rows").append($("#participant_row_template").html())
       //   var new_user_row = $("#participant_rows li").last()
       //    $(new_user_row).attr("id", $(new_user_row).attr("id") + uid)
       // }

       remoteUsers[uid] = user;
       var player = undefined;

       if ($(`#player-${uid}`).length == 0){
         player = $(`<div id="player-${uid}" class="player" style="position:relative;height:100%"></div>`);
         $("#players_container").append(player);
       } else {
         player = $(`#player-${uid}`)
       }

       if (mediaType === 'video') {
         if (user.videoTrack){
           await user.videoTrack.play(`player-${uid}`);
           var video_player = player.find("video").parent()
           PlayerOptions.addName(player, uid)
           PlayerOptions.addOption(player)
           player.find(".video_camera_off_icon").remove()
         }
       }

       if (mediaType === 'audio') {
         var screen_client_uid = (screenClient || {}).uid;
         if (screen_client_uid == uid){
           user.audioTrack.setVolume(0)
           console.log("set volume to 0 since local screen share volume")
         } else {
           if (user.audioTrack){
              user.audioTrack.play();
              if (currentSpeaker){
                user.audioTrack.setPlaybackDevice(currentSpeaker.deviceId)
                .catch(error => {
                  console.error("could not setPlaybackDevice" + error.message)
                })
              }
            }
         }

         if (user._video_added_ == false){ //they are not using video but still want to show video block
           PlayerOptions.addName(player, uid)
           PlayerOptions.addOption(player)
           PlayerOptions.addCameraOffIcon(player)
         }

          if ($("#is_host").length > 0){
            if (user.audioTrack){
              var user_stream = new MediaStream();
              user_stream.addTrack(user.audioTrack.getMediaStreamTrack())
              VolumeMeter.remoteBind(user_stream, uid)
            }
          }
       }

       FixVideos.fix_resolution()
       Rails.ajax({url: `/${subscription_id}/attendee/${uid}/name.js`, type: "get"})

       var recording_id = parseInt($("[data-recording-id]").attr("data-recording-id"))
       if (recording_id){
         Rails.ajax({url: `/recordings/${recording_id}/attendee/${uid}/recording_status.js`, type: "patch"})
       }
       if (maximized_uid){ FixVideos.maximize(maximized_uid) }
     }

    handleUserUnpublished(user, mediaType) {
      console.log(":handleUserUnpublished")
      // console.log("user unpublished")
      // console.log(user)
      // console.log(user.audioTrack)
      // console.log(user.videoTrack)
      // console.log(user._audio_muted_)
      // console.log(user._video_muted_)
      // can we check if they disabled vs unpublished/left?


      const id = user.uid;
       // if (mediaType == 'video'){

       if (screenShareUsers.includes(id) || (!user.hasAudio && !user.hasVideo && user._audio_muted_ == false && user._video_muted_ == false)){
           // screenShareUsers.pop(id)
           console.log("NORMALLY WOULD BUT I WONT remove user---")
            // $(`#player-${id}, #user-row-${id}`).remove();
            // delete remoteUsers[id];
        } else if (all_producer_roles.includes(id) && user._audio_muted_ == true && user._video_muted_ == true){
           console.log("remove producer---")
           $(`#player-${id}`).remove();
           FixVideos.fix_resolution()
           setTimeout(function(){ FixVideos.fix_resolution() }, 500);
         } else {
           console.log("dont remove user ---")
           //the video has been removed by agora so add back username and options
           const player = $(`#player-${id}`);
           PlayerOptions.addName(player, id)
           PlayerOptions.addOption(player)
           if (mediaType == 'video') {
             PlayerOptions.addCameraOffIcon(player)
           }
           Rails.ajax({url: `/${subscription_id}/attendee/${id}/name.js`, type: "get"})
           var recording_id = parseInt($("[data-recording-id]").attr("data-recording-id"))
           if (recording_id){
             Rails.ajax({url: `/recordings/${recording_id}/attendee/${id}/recording_status.js`, type: "patch"})
           }
         }
       // }
       if (id == maximized_uid){
         console.log("set normal")
         FixVideos.setNormal()
       } else if (maximized_uid){
         console.log("set maximize")
         FixVideos.maximize(maximized_uid)
       }

       FixVideos.fix_resolution()
       setTimeout(function(){ FixVideos.fix_resolution() }, 500);

     }

     async connectionStateChanged(curState, prevState, reason){
       // console.log(prevState)
       // console.log(curState)

       // if (prevState == "CONNECTING" && curState == "CONNECTED"){
       //   var record_button = '<i style="font-size:13px;" class="fas fa-circle p-2 bg-gray-200 dark:bg-gray-800 text-red-600 rounded-full"></i><span class="mx-1">record</span>'
       //   $("#record_button").html(record_button)
       // }

     }

}
