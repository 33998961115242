import consumer from "./consumer"

    window.socket_has_connected_to_room = false;
    console.log("try to create action cable consumer")
    window.room_channel = consumer.subscriptions.create(
      {
        channel: "RoomChannel",
        room_id: $("[data-room-id]").attr("data-room-id"),
        room_channel_name: window.location.href.split("?")[0].split("/r/")[1]
      },
      {
        connected: function() {
          window.socket_has_connected_to_room = true
          console.log("connected to room channel")
          if (window.check_room_status) {
            clearInterval(window.check_room_status);
            window.check_room_status = undefined;
            Rails.ajax({url: `/r/${$("[data-room-id]").attr("data-room-id")}/status.js`, type: "get"})
          }
          // Called when the subscription is ready for use on the server
        },

        disconnected: function() {
          window.socket_has_connected_to_room = true
          console.log("disconnected to room")
          if ($("[data-room-id]").length > 0){
            window.check_room_status = setInterval(function(){ Rails.ajax({url: `/r/${$("[data-room-id]").attr("data-room-id")}/status.js`, type: "get"}) }, 5000);
          //   console.log("try to RECONNECT")
          //   this.consumer.connect()
          // } else {
          //   console.log("DO NOT RECONNECT")
          }

          // Called when the subscription has been terminated by the server
        },
        received: async function(data) {
          // console.log(data)

          console.log(`received data from action cable ${data["event"]}`)
          window.socket_has_connected_to_room = true
          switch(data["event"]) {
              case "started_recording":
                if (recorder) {
                  if (recorder.state == "recording"){
                    recorder.stop()
                    await new Promise(r => setTimeout(r, 2000));
                  }
                  recorder = undefined;
                }
                $("#attendee_recording_form").attr("action", data["attendee_recording_url"])
                $("#recording_id").attr("data-recording-id", data["recording_id"])
                $("#started_at").attr("data-timestamp", data["started_at"])
                $("#record_button").replaceWith(data["start_form"])
                Room.show_now_recording()
                break;
              case "update_cant_transcribe":
                $(`#user-row-${data["room_attendee_id"]} .cant_transcribe`).show()
                break;
              case "host_error_message":
                Room.host_error_message(data["message"], data["room_attendee_id"])
                break;
              case "update_subscription_settings":
                $("[data-upload-setting]").attr("data-upload-setting", data["upload_setting"])
                $("[data-max-width]").attr("data-max-width", data["max_width"])
                $("[data-max-height]").attr("data-max-height", data["max_height"]);
                local_frame_rate = data["frame_rate"];
                audio_sample_rate = data["sample_rate"];
                create_transcripts = data["transcripts"];

                if (!recorder) {
                  if (Room.is_iOS()){
                    // await AgoraChannel.connectAgoraDevices();
                    await LocalStream.update()
                  }else {
                    if (localTracks.videoTrack){
                      // release agora video to get better local video
                      localTracks.videoTrack.stop()
                      await client.unpublish(localTracks.videoTrack)
                      await LocalStream.update()

                      await AgoraRTC.createCameraVideoTrack({cameraId: currentCam.deviceId})
                      .then(async track => {
                        localTracks.videoTrack = track;
                        await localTracks.videoTrack.setEnabled(true);
                        if (client.uid) {
                          await localTracks.videoTrack.play(`player-${options.uid}`)
                          await client.publish(localTracks.videoTrack)
                        }
                      })
                      .catch(error => {
                        // alert(error.message)
                      })
                    } else {
                      await LocalStream.update()
                    }

                  }
                }
                break;
              case "stopped_recording":
                $("#stop_recording_button").parent().closest('form').replaceWith(data["stop_recording_button"])
                // $("#recording_finished").show()
                // if ($("#attendee_id").val() == data["room_attendee_id"]){
                //   $("#update_recording_name_form").html(data["update_recording_name_form"]);
                //   options.token = data["token"];
                // }
                Room.stop_showing_now_recording()
                break;
              case "stopping_recording":
                Room.stopping_recording()
                break;
              case "remove_screen_share":
                $(`#player-${data["id"]}`).remove()
                break;
              case "update_attendee_name":
                FixVideos.update_attendee_name(data["id"], data["name"])
                break;
              case "attendee_muted":
                FixVideos.show_mute_attendee_icon(data["id"])
                break;
              case "change_microphone":
                if ($("#attendee_id").val() == data["id"]){ Devices.switchMicrophone(data["microphone"]); }
                break;
              case "change_camera":
                if ($("#attendee_id").val() == data["id"]){ Devices.switchCamera(data["camera"]); }
                break;
              case "force_mute":
                if ($("#attendee_id").val() == data["id"]){ Room.toggleMic(data["id"]) }
                else {
                  $(`#user-row-${data["id"]} .mute_button`).hide()
                  $(`#user-row-${data["id"]} .unmute_button`).show()
                }
                break;
              case "force_unmute":
                if ($("#attendee_id").val() == data["id"]){ Room.toggleMic(data["id"]) }
                else {
                  $(`#user-row-${data["id"]} .unmute_button`).hide()
                  $(`#user-row-${data["id"]} .mute_button`).show()
                }
                break;
              case "force_cam_on":
                if ($("#attendee_id").val() == data["id"]){ Room.toggleVideo(data["id"]) }
                else {
                  $(`#user-row-${data["id"]} .fa-video-slash`).hide()
                  $(`#user-row-${data["id"]} .fa-video`).show()
                }
                break;
              case "force_cam_off":
                if ($("#attendee_id").val() == data["id"]){ Room.toggleVideo(data["id"]) }
                else {
                  $(`#user-row-${data["id"]} .fa-video`).hide()
                  $(`#user-row-${data["id"]} .fa-video-slash`).show()
                }
                break;
              case "attendee_unmuted":
                FixVideos.hide_mute_attendee_icon(data["id"])
                break;
              case "update_meta":
                FixVideos.update_meta(data["room_attendee_id"], data["camera"], data["microphone"], data["resolution"], data["fps"], data["echo_cancel"], data["user_type"], data["microphones"], data["cameras"], data["camera_on"], data["mic_on"])
                break;
              case "update_file_counts":
                FixVideos.update_file_counts(data["room_attendee_id"], data["files_saved"], data["files_uploaded"], data["recording_status"])
                break;
              case "force_echo_cancellation":
                Room.enableEchoCancellation(data["id"])
                break;
              case "force_disabling_echo_cancellation":
                Room.disableEchoCancellation(data["id"])
                break;
              case "force_noise_reduction":
                 Room.enableNoiseReduction(data["id"])
                break;
              case "force_disabling_noise_reduction":
                Room.disableNoiseReduction(data["id"])
                break;
              case "message_created":
                Messages.create(data["attendee_name"], data["attendee_id"], data["message"])
                break;
              case "recording_status":
                FixVideos.recording_status(data["room_attendee_id"], data["recording_status"])
                break;
              case "live_transcript":
                Room.showLiveTranscript(data["message"])
                break
              case "reload_people_waiting":
                Rails.ajax({url: `/r/${$("#room_id").attr("data-room-id")}/people_waiting.js?room_attendee_id=` + $("#attendee_id").val(), type: "get"})
                break;
              case "sound_level":
                if ($("#attendee_id").val() == data["user_id"]){
                  console.log(`updating the volume to ${data["volume"]} from ${local_media_stream.getAudioTracks()[0].volume}`)
                  var volume = data["volume"];
                  localTracks.audioTrack.setVolume(volume * 10)
                  local_media_stream.getAudioTracks()[0].volume = volume;
                }
                break;
              case "user_left":
                console.log("handle user left")
                var uid = data["uid"]
                $(`#player-${uid}, #user-row-${uid}`).remove();
                if (typeof remoteUsers !== 'undefined') { delete remoteUsers[uid] }
                FixVideos.fix_resolution()
                break;
              case "admit":
                if ($("#attendee_id").val() == data["id"]){
                  $("#waiting_room_modal").remove();
                  $("#form_attendee_name").val($("#name_setting").val())
                  AgoraChannel.join()
                }
                break;
              case "waiting_room_status_change":
                if ($("#waiting_room_lock_status").is(':checked').toString() != data["value"].toString()) {
                  $('#waiting_room_lock_status').prop('checked', JSON.parse(data["value"]));

                  var waiting_room_icon_div = $("#waiting_room_icon")
                  if (JSON.parse(data["value"])){
                    waiting_room_icon_div.removeClass("fa-lock-open").addClass("fa-lock")
                    $("#waiting_room_status_disabled_message").hide()
                    $("#waiting_room_status_enabled_empty_message").show()
                  }else {
                    waiting_room_icon_div.removeClass("fa-lock").addClass("fa-lock-open")
                    $("#waiting_room_status_disabled_message").show()
                    $("#waiting_room_status_enabled_empty_message").hide()
                  }
                }
                break;
              default:
                console.log("NOT IMPLEMENTED ACTION FROM CABLE")
                console.log(data)
            }
        }
      }
    );
