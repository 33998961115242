import consumer from "./consumer"

$(document).on('turbo:load', function () {
    window.subscription_channel = consumer.subscriptions.create(
      {
        channel: "SubscriptionChannel",
        subscription_id: $("[data-subscription-id]").attr("data-subscription-id")
      },
      {
        connected: function() {
          console.log("connected to subscription channel")
        },

        disconnected: function() {
            this.consumer.connect()
        },
        received: function(data) {
          console.log(data)

          switch(data["event"]) {
              case "replace":
                $(data["id"]).replaceWith(data["html"])
                break;
              default:
                console.log("NOT IMPLEMENTED ACTION FROM CABLE")
                console.log(data)
            }
        }
      }
    );
});
