export default class Transcribe {

   start(){
     try {
       if (typeof recognition === 'undefined'){
         recognition = new webkitSpeechRecognition() || new SpeechRecognition()
         recognition.continuous = true;

         recognition.onresult = function (event) {
           var current = event.resultIndex;
           var message = encodeURI(event.results[current][0].transcript)
           var name = $("#name_setting").val();
           var attendee_id = $("#attendee_id").val()
           var recording_id = $("#recording_id").attr("data-recording-id")
           Rails.ajax({url: `/r/${$("[data-room-id]").attr("data-room-id")}/transcription.js?message=${message}&name=${name}&attendee_id=${attendee_id}&recording_id=${recording_id}`, type: "post"})
         };

         recognition.onerror = async function (event) {
           console.log('Speech recognition error.');
           console.log(event)
         }

         recognition.onspeechend = function() {
           console.log('---- Speech recognition has stopped.');
          }

         recognition.onaudioend = async () => {
            console.log('---- Audio capturing ended');
          }

          recognition.onend = function () {
            console.log('---- speech on ended');
            if (window.Transcribe.running){ window.Transcribe.start() }
           };

       }
     } catch {
        // Rollbar.error("speech_recognition_undefined");
        Rails.ajax({url: `/r/${$("[data-room-id]").attr("data-room-id")}/no_transcription.js?attendee_id=${$("#attendee_id").val()}`, type: "post"})
     }

     if (recognition){
       this.running = true
       console.log('Voice recognition is on.');
       recognition.start();

   } else {
     console.log("Cant transcribe")
   }

   }

   async stop(){
     this.running = false;
     if (recognition){
       console.log('Voice recognition is off.');
       await recognition.stop();
     }
   }
}
