import {  Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'cardElement', 'cardErrors', 'form' ]

    connect() {
        console.log("connect stripe controller");
        var stripe = Stripe(this.data.get('key'));
        var elements = stripe.elements();
        var style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#E53E3E',
                iconColor: '#E53E3E'
            }
        };

        var card = elements.create('card', {
            style: style
        });

        card.mount(this.cardElementTarget);

        // Handle real-time validation errors from the card Element.
        let controller = this;
        card.addEventListener('change', function (event) {
            var displayError = document.getElementById("card_errors");
            if (event.error) {
                displayError.textContent = event.error.message;
                Rails.enableElement($("#submit_stripe_button")[0])
            } else {
                displayError.textContent = '';
                Rails.enableElement($("#submit_stripe_button")[0])
            }
        });

        // Handle form submission.
        var form = controller.formTarget;
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            stripe.createToken(card).then(function (result) {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById("card_errors");
                    errorElement.textContent = result.error.message;
                    console.log("reenable")
                    Rails.enableElement($("#submit_stripe_button")[0])
                  } else {

                    $('#account_last_four').val(result.token.card.last4)
                    $('#account_expiration_month').val(result.token.card.exp_month)
                    $('#account_expiration_year').val(result.token.card.exp_year)
                    $('#account_brand').val(result.token.card.brand)
                    controller.stripeTokenHandler(result.token);
                }
            });
        });


    }

    // Submit the form with the token ID.
    stripeTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = this.formTarget;
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripe_card_token');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        console.log("subbmited form")
        // Submit the form
        form.submit();
    }
}
