export default class Live {

  constructor() {
   }

   connect_client_events(){

     client.on('stream-published', function (evt) {
       console.log('Publish local stream successfully');
     });

     // when a remote stream is added
     client.on('stream-added', function (evt) {
       console.log('new stream added: ' + evt.stream.getId());
     });

     client.on('stream-removed', function (evt) {
       var stream = evt.stream;
       stream.stop(); // stop the stream
       stream.close(); // clean up and close the camera stream
       console.log("Remote stream is removed " + stream.getId());
     });

     //live transcoding events..
     client.on('liveStreamingStarted', function (evt) {
       console.log("Live streaming started");
     });

     client.on('liveStreamingFailed', function (evt) {
       console.log("Live streaming failed");
     });

     client.on('liveStreamingStopped', function (evt) {
       console.log("Live streaming stopped");
     });

     client.on('liveTranscodingUpdated', function (evt) {
       console.log("Live streaming updated");
     });

     // ingested live stream
     client.on('streamInjectedStatus', function (evt) {
       console.log("Injected Steram Status Updated");
       console.log(JSON.stringify(evt));
     });

   }

   async setTranscoding(){

     var user_ids = [options.uid].concat(Object.keys(remoteUsers).map(id => parseInt(id)))
     var N = user_ids.length;
     var rows = Math.round(Math.sqrt(N));
     var cols = Math.ceil(N / rows);

     var userData = user_ids.map((u, i) => {
      return {
        uid: u,
        x: (i % cols) * Math.round(1280 / cols),
        y: Math.round(Math.floor(i / cols) * (720 / rows)),
        width: Math.round(1280 / cols),
        height: Math.round(720 / rows),
        zOrder: 0,
        alpha: 1,
      };
    });

     var encoding_config = {
        width: 1280,
        height: 720,
        videoFrameRate: 24,
        transcodingUsers: userData,
      }
     await client.setLiveTranscoding(encoding_config).then(() => {
       live_transcoding = true;
      }).catch(error => {
        console.error("could not start transcoding" + error.message)
      })
   }

   async streamToYoutube(){
     if (!live_transcoding){ await this.setTranscoding() }

     var url = $("#youtube_url").val() + "/" + $("#youtube_key").val()
     if ($("#youtube_url").val() != "" && $("#youtube_key").val() != ""){
       await client.startLiveStreaming(url, true).then(() => {
         console.log("start live streaming success");
         $("#init_live_modal").text("Enabled")
         $("#init_live_modal").addClass("text-indigo-600 dark:text-indigo-400")
         $("#stop_all_live_streaming").show()
         $("#live_streaming_icon").css('display', 'inline-block');
         youtube_stream_url = url;
         $("#youtube_section").removeClass("dark:bg-red-700 bg-red-500")
         $("#youtube_section").addClass("dark:bg-green-700 bg-green-500")
        }).catch(error => {
          $("#youtube_section").removeClass("dark:bg-green-700 bg-green-500")
          $("#youtube_section").addClass("dark:bg-red-700 bg-red-500")
          if (youtube_stream_url){ this.stopStreamToYoutube() }
        })
     } else {
       $("#youtube_section").removeClass("dark:bg-green-700 bg-green-500 dark:bg-red-700 bg-red-500")
       if (youtube_stream_url){ this.stopStreamToYoutube() }
     }

   }

   async stopStreamToYoutube(){
     await client.stopLiveStreaming(youtube_stream_url).then(() => {
       youtube_stream_url = undefined;
       if (twitter_stream_url == undefined && facebook_stream_url == undefined){
         $("#init_live_modal").text("Enable")
         $("#init_live_modal").removeClass("text-indigo-600 dark:text-indigo-400")
         $("#youtube_section").removeClass("dark:bg-green-700 bg-green-500 dark:bg-red-700 bg-red-500")
         $("#stop_all_live_streaming").hide()
         $("#live_streaming_icon").hide()
       }
       console.log("stop live streaming success");
     }).catch(error => {
       console.error("could not stop youtube stream" + error.message)
     })
   }

   async streamToTwitter(){
     if (!live_transcoding){ await this.setTranscoding() }

     var url = $("#twitter_url").val() + "/" + $("#twitter_key").val()
     if ($("#twitter_url").val() != "" && $("#twitter_key").val() != ""){
       await client.startLiveStreaming(url, true).then(() => {
         console.log("start live streaming success");
         $("#init_live_modal").text("Enabled")
         $("#init_live_modal").addClass("text-indigo-600 dark:text-indigo-400")
         $("#stop_all_live_streaming").show()
         $("#live_streaming_icon").css('display', 'inline-block');
         twitter_stream_url = url;
         $("#twitter_section").removeClass("dark:bg-red-700 bg-red-500")
         $("#twitter_section").addClass("dark:bg-green-700 bg-green-500")
        }).catch(error => {
          $("#twitter_section").removeClass("dark:bg-green-700 bg-green-500")
          $("#twitter_section").addClass("dark:bg-red-700 bg-red-500")
          if (twitter_stream_url){ this.stopStreamToTwitter() }
        })
     } else {
       $("#twitter_section").removeClass("dark:bg-green-700 bg-green-500 dark:bg-red-700 bg-red-500")
       if (twitter_stream_url){ this.stopStreamToTwitter() }
     }

   }

   async stopStreamToTwitter(){
     await client.stopLiveStreaming(twitter_stream_url).then(() => {
       twitter_stream_url = undefined;
       if (youtube_stream_url == undefined && facebook_stream_url == undefined){
         $("#init_live_modal").text("Enable")
         $("#init_live_modal").removeClass("text-indigo-600 dark:text-indigo-400")
         $("#twitter_section").removeClass("dark:bg-green-700 bg-green-500 dark:bg-red-700 bg-red-500")
         $("#stop_all_live_streaming").hide()
         $("#live_streaming_icon").hide()
       }
       console.log("stop live streaming success");
     }).catch(error => {
       console.error("could not stop twitter stream" + error.message)
     })
   }


   async streamToFacebook(){
     if (!live_transcoding){ await this.setTranscoding() }

     var url = $("#facebook_url").val() + "/" + $("#facebook_key").val()
     if ($("#facebook_url").val() != "" && $("#facebook_key").val() != ""){
       await client.startLiveStreaming(url, true).then(() => {
         console.log("start live streaming success");
         $("#init_live_modal").text("Enabled")
         $("#init_live_modal").addClass("text-indigo-600 dark:text-indigo-400")
         $("#live_streaming_icon").show()
         $("#stop_all_live_streaming").css('display', 'inline-block');
         facebook_stream_url = url;
         $("#facebook_section").removeClass("dark:bg-red-700 bg-red-500")
         $("#facebook_section").addClass("dark:bg-green-700 bg-green-500")
        }).catch(error => {
          $("#facebook_section").removeClass("dark:bg-green-700 bg-green-500")
          $("#facebook_section").addClass("dark:bg-red-700 bg-red-500")
          if (facebook_stream_url){ this.stopStreamToFacebook() }
        })
     } else {
       $("#facebook_section").removeClass("dark:bg-green-700 bg-green-500 dark:bg-red-700 bg-red-500")
       if (facebook_stream_url){ this.stopStreamToFacebook() }
     }

   }

   async stopStreamToFacebook(){
     await client.stopLiveStreaming(facebook_stream_url).then(() => {
       facebook_stream_url = undefined;
       if (youtube_stream_url == undefined && twitter_stream_url == undefined){
         $("#init_live_modal").text("Enable")
         $("#init_live_modal").removeClass("text-indigo-600 dark:text-indigo-400")
         $("#facebook_section").removeClass("dark:bg-green-700 bg-green-500 dark:bg-red-700 bg-red-500")
         $("#live_streaming_icon").hide()
         $("#stop_all_live_streaming").hide()
       }
       console.log("stop live streaming success");
     }).catch(error => {
       console.error("could not stop facebook stream" + error.message)
     })
   }


   async stopAllLiveStreaming(){
    if (facebook_stream_url){ await this.stopStreamToFacebook() }
    if (twitter_stream_url){ await this.stopStreamToTwitter() }
    if (youtube_stream_url){ await this.stopStreamToYoutube() }
     $("#youtube_url").val("")
     $("#youtube_key").val("")
     $("#facebook_url").val("")
     $("#facebook_key").val("")
     $("#twitter_url").val("")
     $("#twitter_key").val("")

   }


}
