// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Tailwinds
// import '../css/application.css'

// require('src/sdk.js')

var AudioContext = window.AudioContext;
if (typeof AudioContext === 'undefined') {
    if (typeof webkitAudioContext !== 'undefined') { AudioContext = webkitAudioContext; }
    if (typeof mozAudioContext !== 'undefined') { AudioContext = mozAudioContext; }
}

require("@rails/ujs").start()
require("@hotwired/turbo-rails")
global.Turbo = Turbo;
require("@rails/activestorage").start()
require("channels")

// require("agora-rtc-sdk-ng")
import AgoraRTC from 'agora-rtc-sdk-ng';
global.AgoraRTC = AgoraRTC;
require("dexie")
require('jquery')
import 'controllers'

require('src/finish_recordings.js')
require('src/fix_videos.js')
require('src/messages.js')
require('src/local_stream.js')
require('src/devices.js')
require('src/volume_meter.js')
require('src/agora_channel.js')
require('src/room.js')
require('src/countdown_timer.js')
require('src/player_options.js')

require("stylesheets/application.css")

import LocalDB from '../src/finish_recordings'
import FixVideos from '../src/fix_videos'
import Messages from '../src/messages'
import LocalStream from '../src/local_stream'
import Transcribe from '../src/transcribe'
import Devices from '../src/devices'
import VolumeMeter from '../src/volume_meter'
import AgoraChannel from '../src/agora_channel'
import Room from '../src/room'
import Live from '../src/live'
import CountdownTimer from '../src/countdown_timer'
import PlayerOptions from '../src/player_options'

// import WebRecorder from '../src/recorder/WebRecorder.js'

// global.webRecorder = new WebRecorder
global.local_db = new LocalDB
global.FixVideos = new FixVideos
global.Messages = new Messages
global.LocalStream = new LocalStream
global.Transcribe = new Transcribe
global.Devices = new Devices
global.VolumeMeter = new VolumeMeter
global.AgoraChannel = new AgoraChannel
global.Room = new Room
global.Live = new Live
global.CountdownTimer = new CountdownTimer
global.PlayerOptions = new PlayerOptions

$( document ).on('turbo:load', function() {
  local_db.load_local_db()
  local_db.check_for_recordings_to_upload()
})

import Dexie from "dexie";
global.Dexie = Dexie

// require("recordrtc")
// import RecordRTC from 'recordrtc';
// global.RecordRTC = RecordRTC;

require('script-loader!blueimp-file-upload/js/vendor/jquery.ui.widget.js');
// require('script-loader!blueimp-load-image/js/load-image.all.min.js');
// require('script-loader!blueimp-canvas-to-blob/js/canvas-to-blob.js');
// require('script-loader!blueimp-file-upload/js/jquery.iframe-transport.js');
require('script-loader!blueimp-file-upload/js/jquery.fileupload.js');
// require('script-loader!blueimp-file-upload/js/jquery.fileupload-process.js');
// require('script-loader!blueimp-file-upload/js/jquery.fileupload-image.js');
// require('script-loader!blueimp-file-upload/js/jquery.fileupload-audio.js');
// require('script-loader!blueimp-file-upload/js/jquery.fileupload-video.js');
// require('script-loader!blueimp-file-upload/js/jquery.fileupload-validate.js');
// require('script-loader!blueimp-file-upload/js/jquery.fileupload-ui.js');


import Rails from '@rails/ujs';
global.Rails = Rails;

// import { DirectUpload } from "@rails/activestorage"
// global.DirectUpload = DirectUpload


String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

String.prototype.toHHMMSSMS = function() {
  var hours, milli_seconds, minutes, sec_num, seconds;
  sec_num = parseInt(this, 10);
  hours = Math.floor(sec_num / 3600);
  minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  seconds = sec_num - (hours * 3600) - (minutes * 60);
  if (this.indexOf('.') === -1) {
    milli_seconds = 0;
  } else {
    milli_seconds = Math.round((60 * parseInt(this.split('.')[1].substring(0, 2))) / 100);
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (milli_seconds < 10) {
    milli_seconds = '0' + milli_seconds;
  }
  return hours + ':' + minutes + ':' + seconds + ':' + milli_seconds;
};

String.prototype.toSeconds = function() {
  var seconds, time, time_array;
  time_array = this.split(':');
  seconds = (+time_array[0]) * 60 * 60 + (+time_array[1]) * 60 + (+time_array[2]);
  time = seconds + (time_array[3] ? time_array[3] / 60 : 0);
  return seconds + (time_array[3] ? time_array[3] / 60 : 0);
};

$.expr[':'].contains = $.expr.createPseudo(function(arg) {
  return function(elem) {
    return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
  };
});

$(document).on('click', '.toggle_mobile_menu', function(e) {
  e.preventDefault()
  $("#mobile_menu").toggle()
});

$(document).on('turbo:load', function() {

  $("#personal_dropdown_button").click(function(){
    $("#personal_dropdown").toggle()
  });

  $("body").off( "click").click(function(event){
    var opening_popup = $(event.target).hasClass("open_popup") || $(event.target).parents(".open_popup").length == 1
    var clicking_on_popup = $(event.target).parents(".popup_opened").length > 0
    if (!opening_popup && !clicking_on_popup){
      $(".popup_opened").hide()
    }
    if (!$(event.target).hasClass("user_button")){
      $(".user_options").hide()
    }

    if (!$(event.target).hasClass("edit_options")){
      $(".options").hide()
    }
  });

});

$(document).on('click', '.row, .older_row', function(event) {
  if ($(this).attr("data-href").length > 0 && $(event.target).attr("href") == undefined ){
    if ($(this).attr("data-new-tab")){
      window.open($(this).attr("data-href"), '_blank');
    } else{
      console.log("navigating to this href2")
      Turbo.visit($(this).attr("data-href"))
    }
  }
});


$(document).on('click', '.modal_background', function(event) {
  $(".modal").hide()
});




$(document).on('click', '.recordings.show .close_modal', function(event) {
  event.preventDefault()
  $(this).parents(".modal_to_close").hide()
});

// recordings show
$(document).on('click', '.recordings.show .delete_individual_recording', function(event) {
  event.preventDefault()
  var attendee_recording_id = $(this).attr("data-attendee-recording-id")
  $(`#delete_individual_modal_${attendee_recording_id}`).show()

});

$(document).on('click', '.recordings.show .view_post_production', function(event) {
  event.preventDefault()
  var attendee_recording_id = $(this).attr("data-attendee-recording-id")
  $(`#post_production_modal_${attendee_recording_id}`).show()
});

$(document).on('click', '.recordings.show #edit_recording', function(event) {
    event.preventDefault()
    $("#show_recoding_name").toggle()
    $("#editing_recording_name").toggle()
    $("#recording_name").focus()
});

$(document).on('click', '.dismiss_notification', function(event) {
    event.preventDefault()
    $(".notification").hide()
});


$(document).on('click', '.recordings.show #cancel_edit_button', function(event) {
        event.preventDefault()
        $("#show_recoding_name").toggle()
        $("#editing_recording_name").toggle()
        $("#recording_name").focus()
    });

$(document).on('click', '.recordings.show #delete_recording', function(event) {
    $("#delete_modal").show()
});

$(document).on('click', '.recordings.show #share_recording', function(event) {
    $("#share_modal").show()
});


$(document).on('click', '.recordings.show #cancel_delete', function(event) {
    $("#delete_modal").hide()
});

$(document).on('click', '.recordings.show #cancel_share', function(event) {
    $("#share_modal").hide()
});

$(document).on('click', '.recordings.show .edit_options', function(event) {
    $(this).parents(".options_container").find(".options").toggle()
});

window.jQuery = $;
window.$ = $;
