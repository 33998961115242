import {  Controller } from "stimulus"

export default class extends Controller {

  connect(){  }

  recordings(event){
    // event.preventDefault()
    var text = $("#search_recordings").val()
    if (text == ""){
      $("#recording_rows .row, #old_recording_rows .older_row").show()
    } else {
      $("#recording_rows .row, #old_recording_rows .older_row").hide()
      $(`#old_recording_rows .older_row .recording_name:contains("${text}")`).parents(".older_row").show()
      $(`#recording_rows .row .recording_name:contains("${text}")`).parents(".row").show()
      $(`#recording_rows .row .recording_room_name:contains("${text}")`).parents(".row").show()
      $(`#old_recording_rows .older_row .recording_room_name:contains("${text}")`).parents(".older_row").show()
    }
  }


}
