import { Controller } from "stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  connect() {
    if (localStorage.hide_practices == "true") {
      this.show_join_recording_room_modal();
    }
    subscription_id = $("[data-subscription-id]").attr("data-subscription-id");
    this.showCorrectDarkLightMode();
  }

  show_join_recording_room_modal(first_time = false) {
    $("#best_practices_modal").remove();
    $("#join_modal").show();

    if (typeof AgoraRTC !== "undefined") {
      Room.check_browser();
      // Room.check_vpn();
      this.setEncodingOptions();
      this.bindEnterKey();
      this.tryConnect();
      //bug with ios15.1 iphone
      // if (Room.is_iOS()){
      client = AgoraRTC.createClient({ mode: "live", codec: "vp9" });
      // } else {
      // client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
      // }

      this.bindClientEvents();

      AgoraRTC.onAutoplayFailed = () => {
        console.log("AgoraRTC.onAutoplayFailed");

        AgoraRTC.onAudioAutoplayFailed = () => {
          if (isAudioAutoplayFailed) return;

          isAudioAutoplayFailed = true;
          const btn = document.createElement("button");
          btn.innerText =
            "Click here to allow browser to play video and audio.";
          btn.onclick = function () {
            isAudioAutoplayFailed = false;
            btn.remove();
          };
          btn.className = "p-4";
          if ($("#pre-local-player").length == 0) {
            Rollbar.error("onAudioAutoplayFailed error");
          } else {
            $("#pre-local-player").prepend(btn);
          }
        };
      };

      this.setBindings();
    } else {
      var that = this;
      setTimeout(() => {
        if (first_time) {
          that.show_join_recording_room_modal((first_time = false));
        } else {
          console.error("window.AgoraRTC not defined");
          Rollbar.error("window.AgoraRTC1_notdefined");
          $("#use_browser").show();
        }
      }, "1000");
    }
  }

  async tryConnect() {
    try {
      MediaRecorder.isTypeSupported;
      await Promise.all([
        Devices.loadSpeakers(),
        Devices.loadMics(),
        Devices.loadCameras(),
      ]);
      if (Room.is_iOS()) {
        await AgoraChannel.connectAgoraDevices();
        await LocalStream.update();
      } else {
        await LocalStream.update();
        await AgoraChannel.connectAgoraDevices();
      }
      $("#join_room").prop("disabled", false).html("Join Room");
      // &#8594;
    } catch (err) {
      if (
        err.toString().includes("PERMISSION_DENIED") ||
        err.toString().includes("Permission denied")
      ) {
        $("#no_permissions").show();
      } else if (
        err.toString().includes("MediaRecorder is unsupported") ||
        err.toString().includes("Can't find variable: MediaRecorder") ||
        err.toString().includes("Can't find variable MediaRecorder")
      ) {
        $("#use_browser").show();
      } else {
        console.log("cant connect to join room");
        Rollbar.error(err);
        throw err;
      }
    }
  }

  bindEnterKey() {
    $(document).keypress(function (e) {
      if ($("#join_modal").length == 1 && (e.keycode == 13 || e.which == 13)) {
        $("#join_room").click();
      }

      if (e.target.id == "message" && (e.keycode == 13 || e.which == 13)) {
        e.preventDefault();
        $("#send_message_button").click();
      }
    });
  }

  setEncodingOptions() {
    try {
      // if (MediaRecorder.isTypeSupported("video/webm;codecs:Av1")){
      //   encoding_options = "video/webm;codecs:Av1;";
      //   } else
      if (MediaRecorder.isTypeSupported("video/webm;codecs:vp9")) {
        encoding_options = "video/webm;codecs:vp9";
      } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
        encoding_options = "video/webm;codecs=vp9";
      } else if (MediaRecorder.isTypeSupported("video/mp4")) {
        encoding_options = "video/mp4";
      } else if (MediaRecorder.isTypeSupported("video/webm;codecs=h264")) {
        encoding_options = "video/webm;codecs=h264";
      } else if (MediaRecorder.isTypeSupported("video/webm;codecs:h264")) {
        encoding_options = "video/webm;codecs:h264";
      } else if (MediaRecorder.isTypeSupported("video/webm;codecs:vp8")) {
        encoding_options = "video/webm;codecs:vp8";
      } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
        encoding_options = "video/webm;codecs=vp8";
      } else if (MediaRecorder.isTypeSupported("video/webm;")) {
        encoding_options = "video/webm";
      } else if (MediaRecorder.isTypeSupported(encoding_options)) {
        // means we are all systems go! - alredy set to basic webm
      } else {
        console.error("CANT FIND A GOOD ENCODING CONFIG");
        Rollbar.error("NO_ENCODING");
        $("#use_browser").show();
      }
    } catch (err) {
      console.log(err.message);
      Rollbar.error("NO_ENCODING_B");
      // Rollbar.error("CANT FIND A GOOD ENCODING CONFIG")
      $("#use_browser").show();
    }
  }

  bindClientEvents() {
    client.setClientRole(
      "host",
      function () {
        console.log("Client role set as host.");
      },
      function (e) {
        console.log("setClientRole failed", e);
        Rollbar.error("set_client_role_failed");
      },
    );

    client.on("user-published", AgoraChannel.handleUserPublished);
    client.on("user-unpublished", AgoraChannel.handleUserUnpublished);
    client.on("connection-state-change", AgoraChannel.connectionStateChanged);
    client.on("user-joined", AgoraChannel.handleUserJoined);
    client.on("user-left", AgoraChannel.handleUserLeft);

    if ($("#is_host").length > 0) {
      client.on("network-quality", AgoraChannel.handleLocalNetworkQuality);
    }

    Live.connect_client_events();
  }

  setBindings() {
    $(document).on("keydown", function (event) {
      if (event.ctrlKey && event.key == "m") {
        Room.toggleMic();
      }
    });

    $("#invite_role_type").on("change", function (e) {
      var url = $("#invite_url").val().split("?")[0];
      var host_token = $("#invite_url").attr("data-host-token");
      var producer_token = $("#invite_url").attr("data-producer-token");
      if (this.value == "Guest") {
        $("#invite_role").val("0");
        $("#invite_url").val(url);
      } else if (this.value == "Host") {
        $("#invite_url").val(url + "?t=" + host_token);
        $("#invite_role").val("1");
      } else if (this.value == "Producer") {
        $("#invite_url").val(url + "?t=" + producer_token);
        $("#invite_role").val("2");
      }
    });

    $("#video_setting, #test_video_setting").on("change", function (e) {
      Devices.switchCamera(this.value);
    });

    $("#test_microphone_setting, #microphone_setting").on(
      "change",
      function (e) {
        Devices.switchMicrophone(this.value);
      },
    );

    $("#test_speaker_setting, #speaker_setting").on("change", function (e) {
      Devices.switchSpeaker(this.value);
    });

    $(document).on("keyup", "#init_name_setting", function () {
      $("#name_setting").val(this.value);
      $("#form_attendee_name").val(this.value);
      Rails.fire($("#attendee_update_name_form")[0], "submit");
    });

    $(document).on("keyup", "#name_setting", function () {
      $("#form_attendee_name").val(this.value);
      Rails.fire($("#attendee_update_name_form")[0], "submit");
    });

    // $(document).on('click', '#stop_recording_button', function() {
    //   setTimeout(function(){
    //      $("#stop_recording_button").prop("disabled", true);
    //                           $("#stop_recording_button").css("opacity", "0.5");
    //                         $("#stop_recording_button span").text(" Stopping... ") }, 1);
    // });

    // only thing that works for some reason
    $("#record_button").click(function (event) {
      if (client.channelName) {
        $("#record_button span").text("Starting...");
        $("#record_button span").addClass("text-gray-600");
        $("#record_button i").css("opacity", "0.5");
        setTimeout(function () {
          $("#record_button").prop("disabled", true);
        }, 1);
      } else {
        event.preventDefault();
        event.stopPropagation();
        alert(
          "You are still joining the channel. Please try again in a few seconds. \n\nIf this continues contact our support by emailing support@iris.fm.",
        );
      }
    });

    // $(window).on('unload', function() {
    //   Rails.ajax({url: `/r/${$("[data-room-id]").attr("data-room-id")}/user_left.js?uid=${options.uid}`, type: "delete"})
    //   if ($("#is_host").length > 0){ Room.check_force_stop_recording() }
    //   if (local_media_stream){ local_media_stream.getTracks().forEach(track => track.stop()); }
    //   for (var trackName in localTracks) {
    //     var track = localTracks[trackName];
    //     if(track) {
    //       track.stop();
    //       track.close();
    //       localTracks[trackName] = undefined;
    //     }
    //   }
    //   client.leave();
    // });

    window.onbeforeunload = function () {
      console.log("UNLOAD");
      Rails.ajax({
        url: `/r/${$("[data-room-id]").attr("data-room-id")}/user_left.js?uid=${options.uid}`,
        type: "delete",
      });
      if ($("#is_host").length > 0) {
        Room.check_force_stop_recording();
      }
      if (local_media_stream) {
        local_media_stream.getTracks().forEach((track) => track.stop());
      }
      for (var trackName in localTracks) {
        var track = localTracks[trackName];
        if (track) {
          track.stop();
          track.close();
          localTracks[trackName] = undefined;
        }
      }
      client.leave();

      //     window.addEventListener("beforeunload", function (e) {
      //         if (!window.uploading) {  return undefined }
      //
      //         var confirmationMessage = 'We are still uploading your local HD recording. '
      //                                 + 'If you leave before saving, your recordings will be lost.';
      //
      //         (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      //         return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      //     });
    };
  }

  showCorrectDarkLightMode() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.querySelector("html").classList.add("dark");
      $(".light_button").hide();
      $(".dark_button").show();
    } else {
      document.querySelector("html").classList.remove("dark");
    }
  }

  joinRoom(event) {
    event.preventDefault();
    if (producer_role) {
      $("#join_modal").hide();
      $("#producer_check_modal").show();
    } else {
      Rails.ajax({
        url:
          `/r/${$("#room_id").attr("data-room-id")}/waiting_room_status.js?room_attendee_id=` +
          $("#attendee_id").val(),
        type: "get",
      });
    }
  }

  removeCustomMessage(event) {
    event.preventDefault();
    $("#custom_message_modal").remove();
  }

  joinRoomAsProducer() {
    Rails.ajax({
      url:
        `/r/${$("#room_id").attr("data-room-id")}/waiting_room_status.js?room_attendee_id=` +
        $("#attendee_id").val(),
      type: "get",
    });
  }

  async toggleVideo(event) {
    event.preventDefault();
    Room.toggleVideo();
  }

  // async updateEchoCancellation(event){
  //   options.echo_cancellation = $(event.target.parentElement).find("input").prop("checked");
  //   $("#toggleC").prop("checked", $(event.target.parentElement).find("input").prop("checked"))
  //   await LocalStream.update()
  //   if (localTracks.audioTrack) {
  //     if (client.uid){ await client.unpublish([localTracks.audioTrack]) }
  //     await localTracks.audioTrack.stop();
  //     await localTracks.audioTrack.close();
  //     localTracks.audioTrack = undefined;
  //   }
  //   localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack(Devices.microphone_config);
  //   if (client.uid){ await client.publish([localTracks.audioTrack]) }
  //
  // }

  async enableEchoCancellation(event) {
    Rails.ajax({
      url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/enable_echo_cancellation.js?force=true`,
      type: "patch",
    });
    // await Room.enableEchoCancellation()
  }

  async disableEchoCancellation(event) {
    Rails.ajax({
      url: `/${subscription_id}/attendee/${$("#attendee_id").val()}/disable_echo_cancellation.js?force=true`,
      type: "patch",
    });
    // await Room.disableEchoCancellation()
  }

  async toggleSpeakers(event) {
    var label = $("#speaker_setting").val();
    if ($("#speaker_setting").val() != "off") {
      label = "off";
    }
    Devices.switchSpeaker(label);
  }

  async toggleMic(event) {
    event.preventDefault();
    Room.toggleMic();
  }

  async testSpeaker(event) {
    var audio = $("#trumpet_audio")[0];
    if (currentSpeaker) {
      await audio.setSinkId(currentSpeaker.deviceId);
      $("#trumpet_audio")[0].play();
    } else {
      alert("No speakers found to play the sound test.");
    }
  }

  copyText(event) {
    event.preventDefault();
    var copyText = document.getElementById("invite_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
    $("#copy_invite_url").text("copied!");
    setTimeout(function () {
      $("#copy_invite_url").text("copy");
    }, 5000);
  }

  toggleChat(event) {
    event.preventDefault();
    $(".popup_opened:not(#chat_dropdown)").hide();
    $("#chat_dropdown").toggle();
    $("#unseen_message_count").text("");
  }

  showInviteModal() {
    $("#invite_modal").show();
  }

  closeInviteModal() {
    $("#invite_modal").hide();
  }

  closeRecordingSettingsModal() {
    $("#recording_settings_modal").hide();
  }

  closeEchoNoiseModal() {
    $("#echo_noise_recommendations_modal").hide();
  }

  closeCantTranscribeModal() {
    $("#cant_transcribe_modal").hide();
  }

  openRoomPanel() {
    $(".popup_opened:not(#room_panel)").hide();
    $("#room_panel").toggle();
  }

  openRecordingSettings() {
    $("#recording_settings_modal").show();
  }

  openEchoNoiseRecommendations(event) {
    event.preventDefault();
    $("#echo_noise_recommendations_modal").show();
  }

  openCantTranscribe(event) {
    event.preventDefault();
    $("#cant_transcribe_modal").show();
  }

  openSettingsDiv() {
    $(".popup_opened:not(#settings_dropdown)").hide();
    $("#settings_dropdown").toggle();
  }

  toggleUserOptions(event) {
    $(".user_options").hide();
    $(event.target)
      .parents(".user_option_button")
      .find(".user_options")
      .toggle();
  }

  retryConnect() {
    $("#no_permissions").hide();
    this.tryConnect();
  }

  switchRole(event) {
    event.preventDefault();
    var role_type = $("#room_role").val();
    var attendee_id = $("#attendee_id").val();

    if (role_type == "Producer") {
      producer_role = true;
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/update_room_role.js?room_role_id=producer`,
        type: "patch",
      });
    } else {
      producer_role = false;
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/update_room_role.js?room_role_id=host`,
        type: "patch",
      });
    }
  }

  async leave(event) {
    event.preventDefault();
    if (local_media_stream) {
      local_media_stream.getTracks().forEach((track) => track.stop());
    }
    remoteUsers = {};
    $("#remote-playerlist").html("");
    await client.leave();
    for (var trackName in localTracks) {
      var track = localTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        localTracks[trackName] = undefined;
      }
    }

    for (var trackName in screenTracks) {
      var track = screenTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        screenTracks[trackName] = undefined;
      }
    }
    if (screenClient) {
      await screenClient.leave();
      screenClient = undefined;
    }
    Room.check_force_stop_recording();
    Room.stop_showing_now_recording();
    console.log("leaving room");
    window.location = $("#leave").attr("data-href");
  }

  switchLightDarkMode(event) {
    if ($(".dark_button").is(":visible")) {
      $("html").removeClass("dark");
      $(".dark_button").hide();
      $(".light_button").show();
      localStorage.theme = "light";
    } else {
      $("html").addClass("dark");
      $(".dark_button").show();
      $(".light_button").hide();

      localStorage.theme = "dark";
    }
  }

  dontShowTipsModal(event) {
    event.preventDefault();
    if ($("#practices_checkbox").is(":checked")) {
      localStorage.hide_practices = true;
    } else {
      localStorage.removeItem("hide_practices");
    }
  }

  async shareScreen(event) {
    event.preventDefault();
    if (options.share_uid == undefined) {
      var attendee_id = $("#attendee_id").val();
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/screen_share_token.js`,
        type: "get",
        success: function (response) {
          Room.startScreenCall();
        },
      });
    } else {
      Room.startScreenCall();
    }
  }

  async stopShareScreen(event) {
    event.preventDefault();
    Room.stopShareScreen();
  }

  showViewOptions(event) {
    event.preventDefault();
    $(event.target).parent().find(".view_options").toggle();
  }

  maximizePlayer(event) {
    event.preventDefault();
    $(event.target).parent().find(".maximize_option").hide();
    $(event.target).parent().find(".minimize_option").show();

    $(".true_frame_option").show();
    $(".remove_true_frame_option").hide();
    var player_container = $(event.target).parents(".player");
    var uid = player_container.attr("id").replace("player-", "");
    FixVideos.maximize(uid);
    $(".view_options").hide();
  }

  minimizePlayer(event) {
    event.preventDefault();
    $(".view_options").hide();
    $(event.target).parent().find(".maximize_option").show();
    $(event.target).parent().find(".minimize_option").hide();

    $(".true_frame_option").show();
    $(".remove_true_frame_option").hide();
    FixVideos.setNormal();
  }

  showTrueFrame(event) {
    event.preventDefault();
    $(".view_options").hide();
    $(event.target).parent().find(".true_frame_option").hide();
    $(event.target).parent().find(".remove_true_frame_option").show();
    var player_container = $(event.target).parents(".player");
    var uid = player_container.attr("id").replace("player-", "");
    FixVideos.showTrueFrame(uid);
  }

  removeTrueFrame(event) {
    event.preventDefault();
    $(".view_options").hide();
    $(event.target).parent().find(".true_frame_option").show();
    $(event.target).parent().find(".remove_true_frame_option").hide();
    var player_container = $(event.target).parents(".player");
    var uid = player_container.attr("id").replace("player-", "");
    FixVideos.removeTrueFrame(uid);
  }

  showSettingsModal(event) {
    event.preventDefault();
    this.show_join_recording_room_modal();
  }

  closeErrorModal(event) {
    event.preventDefault();
    $("#error_modal").hide();
  }

  closeUploadingComplete(event) {
    event.preventDefault();
    $("#uploading_complete").hide();
  }

  goToRecording(event) {
    event.preventDefault();
    subscription_id = $("[data-subscription-id]").attr("data-subscription-id");
    recording_id = $("#recording_id").attr("data-recording-id");
    console.log("going to recording");
    window.location.href = `/${subscription_id}/recordings/${recording_id}`;
  }

  cancelProducerMode(event) {
    event.preventDefault();
    $("#producer_check_modal").hide();
    $("#join_modal").show();
  }

  muteUser(event) {
    event.preventDefault();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    Rails.ajax({
      url: `/${subscription_id}/attendee/${attendee_id}/mute.js?force_mute=true`,
      type: "patch",
    });
    // $(event.target).parents(".user_row").find(".user_options").hide()
  }

  unMuteUser(event) {
    event.preventDefault();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    Rails.ajax({
      url: `/${subscription_id}/attendee/${attendee_id}/unmute.js?force_unmute=true`,
      type: "patch",
    });
    // $(event.target).parents(".user_row").find(".user_options").hide()
  }

  turnOffCameraForUser(event) {
    event.preventDefault();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    Rails.ajax({
      url: `/${subscription_id}/attendee/${attendee_id}/camera_off.js?force_cam_off=true`,
      type: "patch",
    });
    // $(event.target).parents(".user_row").find(".user_options").hide()
  }

  turnOnCameraForUser(event) {
    event.preventDefault();
    console.log("force cam on");
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    Rails.ajax({
      url: `/${subscription_id}/attendee/${attendee_id}/camera_on.js?force_cam_on=true`,
      type: "patch",
    });
    // $(event.target).parents(".user_row").find(".user_options").hide()
  }

  forceToggleEchoCancellation(event) {
    event.preventDefault();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    $(event.target).toggleClass("bg-indigo-500 dark:bg-indigo-700");
    $(event.target).find("span").toggleClass("translate-x-3");

    if ($(event.target).find("span").hasClass("translate-x-3")) {
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/enable_echo_cancellation.js?force=true`,
        type: "patch",
      });
    } else {
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/disable_echo_cancellation.js?force=true`,
        type: "patch",
      });
    }
  }

  // forceDisableEchoCancellation(event){
  //   event.preventDefault()
  //   var attendee_id = $(event.target).parents(".user_row").attr("id").replace("user-row-", '')
  //   Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/disable_echo_cancellation.js?force=true`, type: "patch"})
  //   $(event.target).parents(".user_row").find(".user_options").hide()
  // }

  forceToggleNoiseReduction(event) {
    event.preventDefault();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    $(event.target).toggleClass("bg-indigo-500 dark:bg-indigo-700");
    $(event.target).find("span").toggleClass("translate-x-3");

    if ($(event.target).find("span").hasClass("translate-x-3")) {
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/enable_noise_reduction.js?force=true`,
        type: "patch",
      });
    } else {
      Rails.ajax({
        url: `/${subscription_id}/attendee/${attendee_id}/disable_noise_reduction.js?force=true`,
        type: "patch",
      });
    }
  }

  // forceDisableNoiseReduction(event){
  //   event.preventDefault()
  //   var attendee_id = $(event.target).parents(".user_row").attr("id").replace("user-row-", '')
  //   Rails.ajax({url: `/${subscription_id}/attendee/${attendee_id}/disable_noise_reduction?force=true`, type: "patch"})
  //   $(event.target).parents(".user_row").find(".user_options").hide()
  // }

  waitingRoomStatus(event) {
    var waiting_room_icon_div = $("#waiting_room_icon");
    var host_token = $("[data-host-role-token]").attr("data-host-role-token");
    if (waiting_room_icon_div.hasClass("fa-door-open")) {
      waiting_room_icon_div
        .removeClass("fa-door-open")
        .addClass("fa-door-closed");
      $("#waiting_room_status_disabled_message").hide();
      $("#waiting_room_status_enabled_empty_message").show();
      Rails.ajax({
        url:
          `/r/${$("#room_id").attr("data-room-id")}.js?waiting_room=true&host_token=` +
          host_token,
        type: "patch",
      });
    } else {
      waiting_room_icon_div
        .removeClass("fa-door-closed")
        .addClass("fa-door-open");
      $("#waiting_room_status_disabled_message").show();
      $("#waiting_room_status_enabled_empty_message").hide();
      Rails.ajax({
        url:
          `/r/${$("#room_id").attr("data-room-id")}.js?waiting_room=false&host_token=` +
          host_token,
        type: "patch",
      });
    }
  }

  changeRemoteMicrophones(event) {
    var mic = $(event.target).val();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    Rails.ajax({
      url: `/${subscription_id}/attendee/${attendee_id}/update_meta.js?microphone=${mic}&change_microphone=true`,
      type: "patch",
    });
  }

  changeRemoteCameras(event) {
    var camera = $(event.target).val();
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    Rails.ajax({
      url: `/${subscription_id}/attendee/${attendee_id}/update_meta.js?camera=${camera}&change_camera=true`,
      type: "patch",
    });
  }

  editRemoteCameras(event) {
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    if ($(event.target).hasClass("fa-chevron-circle-down")) {
      $("#user-row-" + attendee_id + " #remote_camera_settings").slideDown(100);
      $(event.target).removeClass("fa-chevron-circle-down");
      $(event.target).addClass("fa-chevron-circle-up");
    } else {
      $("#user-row-" + attendee_id + " #remote_camera_settings").slideUp(100);
      $(event.target).removeClass("fa-chevron-circle-up");
      $(event.target).addClass("fa-chevron-circle-down");
    }
  }

  editRemoteMicrophones(event) {
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    if ($(event.target).hasClass("fa-chevron-circle-down")) {
      $("#user-row-" + attendee_id + " #remote_microphone_settings").slideDown(
        100,
      );
      $(event.target).removeClass("fa-chevron-circle-down");
      $(event.target).addClass("fa-chevron-circle-up");
    } else {
      $("#user-row-" + attendee_id + " #remote_microphone_settings").slideUp(
        100,
      );
      $(event.target).removeClass("fa-chevron-circle-up");
      $(event.target).addClass("fa-chevron-circle-down");
    }
  }

  toggleShowingDevices(event) {
    var attendee_id = $(event.target)
      .parents(".user_row")
      .attr("id")
      .replace("user-row-", "");
    if ($(event.target).hasClass("fa-chevron-down")) {
      $("#user-row-" + attendee_id + " .devices").slideDown(200);
      $(event.target).removeClass("fa-chevron-down");
      $(event.target).addClass("fa-chevron-up");
    } else {
      $("#user-row-" + attendee_id + " .devices").slideUp(200);
      $(event.target).removeClass("fa-chevron-up");
      $(event.target).addClass("fa-chevron-down");
    }
  }

  showLiveModal(event) {
    $("#live_stream_modal").show();
  }

  enableLiveTranscriptions(event) {
    $("#live_transcript").css("display", "inline-block");
    if (Transcribe.running != true) {
      Transcribe.start();
    }
    $("#enable_live_transcription_button").hide();
    $("#disable_live_transcription_button").show();
  }

  disableLiveTranscriptions(event) {
    $("#live_transcript").hide();
    $("#enable_live_transcription_button").show();
    $("#disable_live_transcription_button").hide();
  }

  show_permissions(event) {
    $("#no_permissions").show();
  }

  closeLiveModal(event) {
    $("#live_stream_modal").hide();
  }

  streamToYoutube(event) {
    Live.streamToYoutube();
  }

  streamToTwitter(event) {
    Live.streamToTwitter();
  }

  streamToFacebook(event) {
    Live.streamToFacebook();
  }

  stopAllLiveStreaming(event) {
    Live.stopAllLiveStreaming();
  }
}
