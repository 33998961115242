import {  Controller } from "stimulus"

export default class extends Controller {

  connect(){
    // console.log("sound")
  }



  update_volume(){
    // console.log("update volume")
    // event.preventDefault()
    var sound_level = $(event.target).val()
    var user_id = $(event.target).parents(".user_row").attr("id").replace("user-row-", '')
    // console.log(sound_level)
    // this.close()

    if (options.uid == user_id){
      localTracks.audioTrack.setVolume(sound_level * 2)
      // local_media_stream.getAudioTracks()[0].volume = sound_level;
    }else {
      if (remoteUsers[user_id].audioTrack){
        remoteUsers[user_id].audioTrack.setVolume(sound_level * 2)
      }
    }

    // Rails.ajax({url: `/r/${$("#room_id").attr("data-room-id")}/sound_level.js?user_id=` + user_id +"&volume=" + sound_level , type: "patch"})

    //agora setVolume The value ranges from 0 (mute) to 1000 (maximum). A value of 100 is the original volume.
    //mediastreamtrack volume = A double-precision floating-point number indicating the volume, from 0.0 to 1.0,
    // remoteUsers[].audioTrack.setVolume(sound_level);
    // VolumeMeter.bind(local_media_stream);

    // must update everywhere

  }


  async close(){
    if (local_audio_context){
      if (local_audio_context.state == "running"){
        try { await local_audio_context.close() }
        catch (InvalidStateError) { console.log("InvalidStateError: Can't close an AudioContext twice ") }
      }
    }
  }

}
